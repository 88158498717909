/* =================reset css starts here=================  */
*{margin:0;padding:0;}
h1,h2,h3,h4,h5,h6,p,ul,li,body,html,form,fieldset{margin:0;padding:0;outline:none;border:0;}
a{text-decoration:none;border:0;outline:0;}
ul{list-style:none;}
a:focus,input:focus,textarea:focus,*:focus{outline:0!important;}
/* =================reset css ends here================= */
body{padding-top:80px;font-size:16px;line-height:1.5;font-weight:400;font-family: "Poppins", sans-serif;color:#000;background:var(--bs-white);-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.container.ei-container {width: calc(100% - 16px);max-width: 1320px;}
a,input,button{display:inline-block;text-decoration:none;color:inherit;outline:none;transition:all 0.2s ease-in;-webkit-transition:all 0.2s ease-in;}
a img{border:0px none;}
a:hover{outline:none;color:var(--green-color);text-decoration:none;}
a:active{outline:none;text-decoration:none;}
a:focus{outline:none;outline-offset:0px;text-decoration:none;color:inherit;}
img{max-width:100%;border:0;height:auto;}
h1,h2,h3,h4,h5,h6{margin:0px;padding:0px;margin-bottom:24px;line-height:1.5;font-weight:700;color:inherit;}
h1{font-size:58px;}
h2{font-size:48px;}
h3{font-size:40px;}
h4{font-size:24px;}
h5{font-size:20px;}
h6{font-size:18px;}
p{margin:0px;padding:0px;margin-bottom:24px;}
strong{font-weight:700;}
b{font-weight:400;}
p:last-child{margin-bottom:0;}
.ei__btn{position: relative; border-radius:4px;font-weight:500;padding:7px 20px;min-width:71px;font-size:12px;line-height:18px;box-shadow:none;transition:all 0.2s ease-in;-webkit-transition:all 0.2s ease-in;font-family:"Poppins",sans-serif;}
.ei__btn-default,.ei__btn-default:focus{color:#fff;background-color:#47AD1D;border:solid 1px #47AD1D;}
.ei__btn-default:hover,.ei__btn-default:active{color:#fff!important;background-color:#227300!important;border-color:#227300!important;}
.ei__btn-primary,.ei__btn-primary:focus{color:#fff;background-color:#227300;border:solid 1px #227300;}
.ei__btn-primary:hover,.ei__btn-primary:active{color:#fff!important;background-color:#47AD1D!important;border-color:#47AD1D!important;}
.ei__btn-transparent,.ei__btn-transparent:focus{color:#000;background-color:transparent;border:solid 1px transparent!important}
.ei__btn-transparent:hover,.ei__btn-transparent:active{color:#000;background-color:#F5F5F5;border-color:#F5F5F5;}
.ei__btn.btn-sm{padding:7px 25px 7px 35px!important;}
.ei__btn.btn-block{min-width:100%;}
.ei__btn + .ei__btn{margin-left:10px;}
.dropdown-toggle::after{content: none!important;}
.ei__dropdown-menu .dropdown-menu {min-width: 272px;border-radius: 6px;border-color: rgb(0 0 0 / 20%);padding: 20px 18px 20px 20px;}
.dropdown-menu.ei__dropdown-menu-md {min-width: 355px;}
#eiHeader {width: 100%; position: fixed;left: 0;top: 0;right: 0;padding: 0;z-index: 99;transition: all 0.2s ease-in;-webkit-transition: all 0.2s ease-in;}
#eiHeader:before{content: '';width: 100%;height: 1px;background-color:rgb(0 0 0 / 10%);position: absolute;bottom: 0;left: 0;right: 0;}
#eiHeader .navbar.ei__navbar{width:100%; height: 80px;padding:0;}
#eiHeader:after{content: '';width: 100%;height: 100%;background-color: #fff;position: absolute;top: 0;left: 0;right: 0;bottom: 0;transform: translateY(-100%);}
#eiHeader nav{position: relative;z-index: 2;}
.fixed header#eiHeader {box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);}
.fixed header#eiHeader:after{transform: none;}
.ei__nav-inside {width: 100%;}
.ei__header-right{gap: 10px;}
.ei__header-right .ei__btn {padding: 7px 25px 7px 20px;}
.ei__header-right .ei__btn-primary img{filter: brightness(0) invert(1);}
.asc-icon {width: 17px;height: 16px;display: flex;align-items: center;justify-content: center;position: absolute;left: 8px;}
.dsc-icon {width: 15px;height: 14px;display: flex;align-items: center;justify-content: center;position: absolute;right: 9px;}
.icon {width: 15px;height: 14px;display: flex;align-items: center;justify-content: center;position: absolute;right: 9px;}
.ei__filter-content-box{display:block;position:relative;cursor:pointer;font-size: 14px;line-height: 1.2;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;padding: 2px 0 2px 30px;margin-bottom:0;color: #1E1E1E;}
.ei__filter-content-box input{position:absolute;opacity:0;cursor:pointer;height:0;width:0;}
.ei__checkmark{position:absolute;top: 0px;left:1px;height:20px;width:20px;background-color:#eee;border-radius:4px;background:var(--bs-white);border:2px solid #e4e4e4;border-radius:4px;}
.ei__filter-content-box:hover input ~ .ei__checkmark{background-color:#e4e4e4;}
.ei__filter-content-box input:checked ~ .ei__checkmark{background-color:#47AD1D;border:none;}
.ei__checkmark:after{content:"";position:absolute;display:none;}
.ei__filter-content-box input:checked ~ .ei__checkmark:after{display:block;}
.ei__filter-content-box .ei__checkmark:after{left:8px;top:3px;width:5px;height:11px;border:solid white;border-width:0 1px 1px 0;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);}
.ei__fsl-item+.ei__fsl-item {margin-top: 10px;}
#eiHeader .form-control {font-size: 14px;line-height: 1.3;color: #000;border-radius: 0;border-top: 0;border-left: 0;border-right: 0;padding-left: 0.2rem;padding-right: 25px;}
#eiHeader  .form-floating>label{padding-left: 0;}
.formChevronDown-icon {width: 22px;height: 22px;display: flex;align-items: center;justify-content: center;position: absolute;right:6px;bottom:6px;border: 0;background: transparent;}
.form-control:focus{box-shadow: none!important;}
.ei__content-container {padding: 40px 0; border-bottom: 6px solid #F5F5F5;}
.ei__heading{margin-bottom: 30px;}
.ei__heading h6 {font-size: 28px;font-weight: 600;color: #1E1E1E;margin-bottom: 0;text-transform: uppercase;}
.ei__heading h6+p {margin-top: 8px;}
.page-footer-container{padding: 40px 0;}
.ei__img-content {width: 327px;border-radius: 10px;}
.ei__text-content { padding-left: 80px; }
.ei__text-box { max-width: 833px; }
.ei__text-box > h6 { font-size: 24px; font-weight: 600; margin-bottom: 20px;}
.ei__coce-upper {padding: 14px 0 0px;padding-left: 3%;}
.ei__coce-image-box {width: 290px;}
.ei__coce-image {padding-bottom: 104%;}
.ei__coce-image img {top: 0; right: 0; bottom: 0; left: 0;}
.ei__coce-content {padding-left: 4%;width: calc(100% - 290px);padding-top: 10px;}
.ei__coce-content-box {max-width: 695px;}
.ei__coce-content-box h4 {margin-bottom: 20px;}
.ei__coce-content-box h5 {font-size: 16px;  margin-bottom: 6px;}
.ei__coce-lower {margin-top: 30px; border-top: 1px solid rgb(0 0 0 / 10%); padding-top: 30px;}
.ei__team-list {width: calc(100% + 80px); margin-left: -40px;}
.ei__team-item {padding: 0 40px; position: relative;}
.ei__team-item::after {content: ''; position: absolute; right: 0; top: 50%; height: calc(100% - 36px); width: 1px; background: rgb(0 0 0 / 10%); transform: translateY(-50%);}
.ei__team-item:last-child::after {background: none;}
.ei__team-image {width: 92px; height: 92px; border: 1px solid rgb(0 0 0 / 20%);}
.ei__team-text-box {padding-left: 25px; width: calc(100% - 92px);}
.ei__team-text-box h5 { margin-bottom: 10px;}
.ei__ttb-flag {width: 40px;}
.ei__ci-content-left {width: 35.5%;padding-right: 24px;border-right: 1px solid rgb(0 0 0 / 20%);}
.ei__ci-content-right {width: 64.5%;padding: 0 24px;}
.ei__ci-text-box{max-width: 675px;}
.ei__left-box {width: 58%;}
.ei__ci-img {width: 100%;position: relative;padding-bottom: 50.5%;border-radius: 4px;overflow: hidden;}
.ei__ci-img > img {width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;object-fit: cover;}
.ei__logo-list {width: 100%;max-width: 130px;margin: 0 auto;}
.ei__right-box {width: 42%;}
.ei__ci-text-box{gap: 80px;}
.ei__icr-left-box > h5 { font-size: 24px; font-weight: 600; margin-bottom: 5px; color: #1E1E1E; }
.ei__icr-left-box { font-size: 14px; line-height: 1.5; max-width: 340px; }
.ei__icr-left-box > h6 { font-size: 14px; font-weight: 400; margin-bottom: 28px; }
.ei__icr-right-box { font-size: 15px; font-weight: 400; line-height: 1.6; color: #000000; }
.ei__icr-right-box > a { font-size: 15px; font-weight: 600; line-height: 1.5; padding-left: 10px; }
.ei__icr-right-list { gap: 21px; padding: 10px 0; }
.ei__icr-right-box > a:hover { color: rgb(0 0 0 / 60%); transition: .2s ease-in; }
.ei__icr-label {color: rgb(0 0 0 / 50%);}
.map {max-width: 1180px;margin: -24px auto 0;}
.map-wrap {position: relative;width: 100%;padding-bottom: 59.5%;}
.map-wrap .map-box{width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;object-fit: cover;}
.map-outer{padding: 14px 0 0 0;}
.total-contacted-label {font-size: 20px;line-height: 1.2;position: relative;z-index: 2;}
.pin {width: 89px;height: 89px;position: absolute;font-size:76%;font-weight: 700;color: #227300;}
.pin img {width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;}
.pin.pin-1 {left: 13%;top: 41%;}
.pin.pin-2 {left: 45.5%;top: 37.5%;}
.pin.pin-3 {left: 73%;top: 37%;}
.pin.pin-4 {left: 26.5%;bottom: 17%;}
.pin.pin-5 {left: 49%;bottom: 28%;}
.pin.pin-6 {left: 81%;bottom: 17%;}
.ei__report-text-upper { border-bottom: 1px solid rgb(0 0 0 / 10%); padding: 0 0 25px; }
.ei__rtul-box { font-size: 14px; font-weight: 400; line-height: 1.5; }
.ei__rtul-box > h6 { font-size: 40px; font-weight: 600; margin-bottom: 10px; }
.ei__rtul-box > a { color: #47AD1D; text-decoration: underline; }
.ei__rtul-box > a:hover { text-decoration: none;}
.ei__rtur-box-img { width: 235px; margin-bottom: 30px; }
.ei__rtur-box-text { font-size: 14px; font-weight: 400; line-height: 1.5; }
.ei__rtur-box-text >div:last-child { margin-bottom: 0;}
.ei__rtur-box-text > div { margin-bottom: 6px; }
.ei__rtur-box-text > div > strong { font-weight: 600; }
.ei__report-text-lower { padding: 25px 0 0; }
.ei__rtlc-img { width: 162px; border-radius: 10px; overflow: hidden; flex-shrink: 0; }
.ei__rtlc-text { padding-left: 50px; flex-grow: 1;}
.ei__rtlc-text-box > h6 { font-size: 20px; font-weight: 600; letter-spacing: -.3px; margin-bottom: 10px; }
.ei__rtlc-text-box { font-size: 15px; font-weight: 400; line-height: 1.5; }
.ei__rtlc-text-box > a { font-size: 14px; color: #47AD1D; margin-bottom: 20px;}
.ei__rtlc-text-box > a:hover { text-decoration: underline;}
.ei__rtlctb-box-list strong {font-weight: 600;padding-left: 10px;color: #000;}
.ei__rtlctb-box-list {margin-bottom: 10px;color: rgb(0 0 0 / 50%);}
.ei__rtlctb-box-list:last-child { margin-bottom: 0; }
/* `xl` applies to large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
.ei__text-content {padding-left: 30px;}
.ei__heading h6 {font-size: 24px;}
.ei__text-box > h6 {font-size: 22px;}
}
/* `lg` applies to medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
.ei__left-box {width: 100%;}
.ei__right-box {width: 100%;margin-top: 15px;}
.ei__logo-list {max-width: 100%;margin: 0 auto;display: flex;align-items: center;gap: 15px;}
.pin{width: 60px;height: 60px;font-size: 60%;}
.ei__rtul-box-img {width: 185px;margin: 15px 0;}
.ei__rtul-box { font-size: 13px; }
.ei__rtul-box > h6 { font-size: 30px; margin-bottom: 5px; }
.ei__rtur-box-text { font-size: 13px; }
.ei__rtlc-text-box > h6 { font-size: 18px; margin-bottom: 5px; }
.ei__rtlc-text-box { font-size: 13px;}
.ei__rtur-box-img {width: 200px;}
}
/* `md` applies to small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
.ei__text-content {padding-left: 0;}
.ei__img-content {width: 300px;margin-bottom: 25px;}
.ei__coce-upper {padding: 0;}
.ei__coce-image-box {width: 60%;}
.ei__coce-content {width: 100%; padding-left: 0; margin-top:0;padding-top: 0;}
.ei__coce-content-box {font-size: 16px;}    
.ei__coce-content-box h4 {font-size: 20px; margin-bottom: 10px;}
.ei__coce-lower {margin-top: 24px; padding-top: 24px;}
.ei__team-list {width: calc(100% + 40px); margin-left: -20px;}
.ei__team-item {padding: 0 20px;}
.ei__team-image {width: 72px; height: 72px;}
.ei__team-text-box {padding-left: 15px; width: calc(100% - 72px);}
.ei__team-text-box h5 { margin-bottom: 5px; font-size: 16px;}
.ei__ttb-flag {width: 30px;}
.ei__team-item::after {height: calc(100% - 20px);}
.ei__right-box{margin-bottom: 25px;}
.ei__ci-content-right {width: 100%;padding: 25px 0 0;}
.ei__ci-content-left {width: 100%;padding-right: 0;border-right: none;border-bottom: 1px solid rgb(0 0 0 / 20%);}
.ei__icr-left-box > h6 {margin-bottom: 15px;}
.ei__icr-left-box > h5 {font-size: 22px;}
.total-contacted-label {font-size: 16px;}
.pin {width: 30px;height: 30px;font-size: 32%;}
.ei__report-text-upper { flex-direction: column; }
.ei__rtur-box-text {text-align: start !important; padding-top: 10px;}
.ei__coce-content-box h4 {font-size: 18px;}
}

/* `sm` applies to x-small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
p{margin-bottom: 15px;}
.ei__img-content {width:100%;margin-bottom: 15px;}
.ei__heading h6 {font-size: 20px;}
.ei__text-box > h6 {font-size: 18px;margin-bottom: 15px;}
.ei__coce-image-box{margin: 0 auto;}
.ei__ci-text-right {margin-top: 15px;}
.ei__icr-right-list {gap: 12px;padding:0;}
.ei__icr-left-box > h6 {margin-bottom: 12px;}
.map-outer{padding: 0;}
.total-contacted-label {font-size: 12px;}
.ei__heading {margin-bottom: 15px;}
.map {margin: -14px auto 0;}
.ei__rtul-box > h6 { font-size: 22px; }
.ei__etl-content { flex-direction: column;}
.ei__rtlc-img { width: 100%; }
.ei__rtlc-text { padding: 20px 0 0; }
.ei__rtlc-text-box > a { font-size: 13px; margin-bottom: 10px; }
.ei__dropdown-menu .dropdown-menu{padding: 15px;}
#eiHeader .form-control{font-size: 12px;}
.ei__filter-content-box{font-size: 12px;}
.ei__dropdown-menu .dropdown-menu{min-width: 220px;}
.dropdown-menu.ei__dropdown-menu-md{min-width: 280px!important;}
}
/* `xs` applies to x-small devices (portrait phones, less than 360px) */
@media (max-width: 360.98px) {
.ei__team-image {width: 52px; height: 52px;}
.ei__team-text-box {width: calc(100% - 52px);}
.ei__ttb-flag {width: 24px;}
.ei__team-text-box h5 {font-size: 14px;}
.ei__heading h6 {font-size: 18px;}
.pin {width: 24px;height: 24px;font-size: 24%;}
}

@media print {
* {
color: #000 !important;
background-color: #fff !important;
text-align: left !important;
/* padding: 0 !important;
margin: 0 !important; */
border: 0 !important;
}
body{padding: 0;}
header,
#footer,
aside,
form{
display: none !important;
}  
h1,
h2,
h3,
h4,
h5,
h6 {
text-transform: none !important;
font-weight: 700;
margin-bottom: 15px !important;
}
*:not(a):before,
*:not(a):after {
display: none !important;
}
a:after {
content: "(" attr(href) ")";
}
img,
ul {
margin-bottom: 30px !important;
}
p {
margin-bottom: 20px !important;
}
a {
color: #000 !important;
border: 0 !important;
}
ul:not([class]) {
list-style: disc;
list-style-position: inside;
}
ul:not([class]) > li {
padding-left: 0;
}
[class^="col-"],.ei__ci-content-left,.ei__ci-content-right ,.ei__rtu-right,.ei__rtu-left{
width: 100%;
min-width: 100% !important;
margin: 0 !important;
}
.ei__rtu-right{padding-top: 10px;}
.ei__content-container{padding: 0;}
.ei__content-container.ei__text-img-container{padding-top: 0;}
.ei__ci-content-left{margin-bottom: 15px;padding-right: 0;}
.ei__coce-content{padding-left: 0;}
.ei__coce-upper{padding: 0;}
.ei__coce-content{width: 100%;padding-left: 0;}
.ei__heading{margin-bottom: 15px;}
.ei__ci-text-box{max-width: 100%;}
.exclude-print,
.btn {display: none !important;} 
.ei__rtur-box-img{width: 180px;margin-bottom: 0;}
.ei__rtul-box > h6{font-size: 30px;margin-bottom: 0;}
.ei__report-text-upper{padding-bottom: 0;}
.ei__report-text-lower{padding-top: 15px;}
.ei__rtlc-text{padding-left: 0;padding-bottom: 10px;}
.ei__rtlc-text-box > a{margin-bottom: 10px;}
.ei__rtlctb-box-list{margin-bottom: 5px;}
.ei__text-content{padding-left: 0;}
.ei__logo-list{max-width: 100%;margin: 0;}
.page-footer-container{padding: 0;}
.ei__ci-text-box{gap: 0;max-width: 100%;flex-flow: column;}
.ei__ci-content-right{width: 100%;padding: 0;}
.ei__ci-text-left {width: 100%;}
.ei__ci-text-right{width: 100%;}
.ei__icr-left-box > h6{margin-bottom: 15px;}
.ei__icr-right-list{padding: 0;gap: 6px;}
.ei__coce-lower{padding: 0;margin: 0;}
.ei__team-list{padding-bottom: 15px;}
}