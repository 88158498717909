.create-invoice *,
::after,
::before {
  box-sizing: border-box;
}
.create-invoice html,
.create-invoice body {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
}
.create-invoice h1,
.create-invoice h2,
.create-invoice h3,
.create-invoice h4,
.create-invoice h5,
.create-invoice h6 {
  margin: 0px;
  padding: 0px;
  margin-bottom: 24px;
  line-height: 1.5;
  font-weight: 700;
  color: inherit;
}
.create-invoice body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.create-invoice .wep-page-wrapper {
  width: 100%;
  min-height: 30vh;
  background-color: #fff;
}
.create-invoice .wp-inside-wrapper {
  width: 100%;
  max-width: 1440px;
  padding: 20px 55px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

.create-invoice .page-head-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.create-invoice .ph-left h4 {
  font-size: 36px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 28px;
}
.create-invoice .ph-invoice-text {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
}
.create-invoice .ph-invoice-text span {
  display: inline-block;
  color: #227300;
  padding-left: 5px;
}

.create-invoice .ph-right {
  text-align: right;
}
.create-invoice .ph-logo {
  width: 98px;
  margin-left: auto;
  margin-bottom: 7px;
}
.create-invoice .ph-address {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 10px;
}
.create-invoice .ph-link-box {
  display: flex;
  align-items: center;
  gap: 25px;
  font-size: 14px;
  margin-bottom: 5px;
}
.create-invoice .ph-link-box strong {
  font-weight: 700;
  display: inline-block;
  margin-right: 4px;
}
.create-invoice .ph-checkbox-holder {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.create-invoice .tick-checkbox.form-check {
  padding-left: 35px;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  text-align: left;
  position: relative;
  color: #333230;
}
.create-invoice .tick-checkbox.form-check input[type="checkbox"].form-check-input {
  display: none;
}
.create-invoice .tick-checkbox.form-check input[type="checkbox"] + .form-check-label {
  cursor: pointer;
  line-height: inherit;
}
.create-invoice .tick-checkbox.form-check input[type="checkbox"] + .form-check-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  border: 2px solid #000;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in;
}
.create-invoice .tick-checkbox.form-check input[type="checkbox"].form-check-input:checked + .form-check-label::before {
  border-color: #227300;
  background: #227300;
}
.create-invoice .tick-checkbox.form-check input[type="checkbox"] + .form-check-label::after {
  content: "";
  position: absolute;
  left: -1px;
  top: -2px;
  width: 24px;
  height: 24px;
  display: block;
  background: url(../images/tick-icon.svg) no-repeat center / 22px 18px;
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s ease-in;
}
.create-invoice .tick-checkbox.form-check input[type="checkbox"].form-check-input:checked + .form-check-label::after {
  opacity: 1;
  transform: scale(1);
}
.create-invoice .tick-checkbox.form-check input[type="checkbox"].form-check-input:disabled + .form-check-label::before {
  background: #f6f6f6;
}
.create-invoice .page-divider {
  width: 100%;
  height: 1px;
  background-color: #000;
  margin: 19px 0 26px;
}
.create-invoice .input-two-group-row {
  margin-top: 8px;
  margin-bottom: 8px;
}
.create-invoice .input-group-row,
.input-two-group-row {
  width: calc(100% + 20px);
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
}
.create-invoice .igr-column,
.create-invoice .input-two-column {
  width: 50%;
  padding: 0 10px;
}
.create-invoice .igr-full-column {
  width: 100%;
  padding: 0 10px;
}
.create-invoice .igr-box {
  width: 100%;
}
.create-invoice label {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 8px;
  display: inline-block;
}
.create-invoice .igr-input-group,
.input-two-wrap {
  width: calc(100% + 8px);
  margin-left: -4px;
  display: flex;
  flex-wrap: wrap;
}
.create-invoice .igr-input,
.input-two-col {
  width: 50%;
  padding: 0 4px;
  flex-grow: 1;
}
.create-invoice .form-control {
  padding: 12px 9px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  line-height: 1.4;
  height: 42px;
  display: block;
  background: #ffff;
}
.create-invoice .company-address {
  padding: 12px 9px;
  width: 300px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  line-height: 1.4;
  height: 25px;
  display: block;
  background: #ffff;
}
.create-invoice .sign-canvas {
  padding: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  line-height: 1.4;
  height: 100%;
  display: block;
  background: #ffff;
}
.create-invoice .sign-canvas-img {
  padding: 12px 9px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  line-height: 1.4;
  height: 200px;
  display: block;
  background: #ffff;
}
.create-invoice textarea.form-control {
  height: 100px;
  resize: none;
}
.create-invoice .table-wapper {
  margin-top: 16px;
  width: 100%;
  overflow-x: auto;
}
.create-invoice .table {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0 0;
}
.create-invoice .table > thead {
  vertical-align: bottom;
}
.create-invoice .table td,
.create-invoice .table th {
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #000;
}
.create-invoice .table td {
  padding: 6px 10px;
  height: 36px;
}
.create-invoice .table th {
  padding: 6px 10px;
}
.create-invoice table.table tr th,
.create-invoice table.table tr td {
  border-right: 1px solid #000;
  white-space: nowrap;
}
.create-invoice table.table tr th:first-child,
.create-invoice table.table tr td:first-child {
  border-left: 1px solid #000;
}
.create-invoice table.table tr th {
  border-top: 1px solid #000;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.5;
}
.create-invoice table.table tr td {
  font-size: 15px;
}
.create-invoice table.table tr td strong {
  font-weight: 600;
}
.create-invoice table.table tr th:nth-child(odd) {
  text-align: left;
}
.create-invoice table.table tr th:nth-child(even) {
  text-align: right;
}
.create-invoice table.table tr th:first-child {
  border-top-left-radius: 4px;
}
.create-invoice table.table tr th:last-child {
  border-top-right-radius: 4px;
}
.create-invoice table.table tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}
.create-invoice table.table tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}
.create-invoice .text-input {
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 4px 4px;
  font-size: 14px;
  line-height: 1;
  max-width: 301px;
  height: 24px;
  display: flex;
  align-items: center;
}
.create-invoice .sales-tax-input {
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 4px 4px;
  font-size: 14px;
  line-height: 1;
  max-width: 70px;
  height: 24px;
  display: flex;
  align-items: center;
}
.create-invoice .text-input-area {
  border-radius: 4px;
  border: 1px solid #cccccc;
  resize: none;
  width: 100%;
  height: 40px;
  padding: 4px 8px;
  font-size: 14px;
  vertical-align: middle;
}
.create-invoice .text-area {
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 6px 6px;
  font-size: 14px;
  line-height: 1;
  min-width: 100% !important;
  height: 60px;
  display: flex;
  align-items: center;
}
.create-invoice table.table td[colspan="4"] {
  height: 64px;
}
.create-invoice address {
  margin-bottom: 0;
  font-style: normal;
}
.create-invoice .input-group {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  width: 171px;
  overflow: hidden;
}
.create-invoice .input-group-st {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  width: 70px;
  overflow: hidden;
}
.create-invoice .input-group-text {
  font-size: 14px;
  background: #f5f5f5;
  display: inline-block;
  width: 25px;
  height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-invoice .input-group-text + input {
  display: inline-block;
  border: 0;
  width: 85%;
  padding-left: 4px;
}

.create-invoice .three-column-row {
  width: calc(100% + 40px);
  margin-left: -20px;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0 0px;
}
.create-invoice .three-column-col {
  width: 33.33%;
  padding: 0 20px;
}
.create-invoice .t-c-label {
  font-weight: 600;
}
.create-invoice .t-c-row {
  display: flex;
  align-items: center;
  gap: 4px;
}
.create-invoice .t-c-box {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 4px;
}
.create-invoice .page-bottom-row {
  font-size: 14px;
  line-height: 18px;
  padding: 1px 0 0;
}
.create-invoice .btn {
  position: relative !important;
  border-radius: 5px;
  font-weight: 400;
  padding: 6px 7px !important;
  min-width: 63px !important;
  font-size: 13px !important;
  line-height: 1;
  box-shadow: none;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  display: flex;
  align-items: center;
}
.create-invoice .btn-nxt {
  border-radius: 0;
  font-weight: 400;
  padding: 12.2px;
  min-width: 150px;
  font-size: 0.875rem;
  line-height: 1;
  box-shadow: none;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  position: relative;
}
.create-invoice .btn-loading.disabled {
  background-color: #47ad1d;
  pointer-events: none;
  opacity: 0.5;
}
.create-invoice .btn-default,
.create-invoice .btn-default:focus {
  color: #fff;
  background-color: #47ad1d;
  border: solid 1px #47ad1d;
}
.create-invoice .btn-default:hover,
.create-invoice .btn-default:active {
  color: #fff !important;
  background-color: #227300 !important;
  border-color: #227300 !important;
}

.create-invoice .btn-primary,
.create-invoice .btn-primary:focus {
  color: #fff;
  background-color: #227300;
  border: solid 1px #227300;
}
.create-invoice .btn-primary:hover,
.create-invoice .btn-primary:active {
  color: #fff !important;
  background-color: #47ad1d !important;
  border-color: #47ad1d !important;
}
.create-invoice .vertical-line-border {
  border-right: 0.5px solid black;
}

.create-invoice .btn img {
  filter: brightness(0) invert(1);
}
.create-invoice .asc-icon {
  width: 13px !important;
  height: 13px !important;
  margin-right: 6px !important;
}
.create-invoice .black-dash {
  display: inline-block;
  width: 150px;
  height: 1px;
  margin-bottom: 6px;
  margin-left: 4px;
  margin-right: 4px;
  background-color: black;
  vertical-align: bottom;
}
.create-invoice .ph-btn-group {
  display: flex;
  align-items: center;
  gap: 6px !important;
  margin-bottom: 26px !important;
}
.create-invoice .ph-btn-item {
  display: flex !important;
  align-items: center !important;
}
@media (max-width: 991.98px) {
  .wp-inside-wrapper {
    padding: 24px;
  }
  .page-head-wrap {
    flex-flow: column-reverse;
  }
  .ph-left {
    width: 100%;
  }
  .ph-right {
    text-align: left;
    width: 100%;
    margin-bottom: 15px;
  }
  .ph-logo {
    margin: 15px 0 8px;
  }
  .ph-checkbox-holder {
    display: inline-block;
  }
  .igr-column,
  .input-two-column {
    width: 100%;
  }
  .ph-left h4 {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .ph-invoice-text {
    font-size: 18px;
  }
  .igr-column + .igr-column,
  .input-two-column + .input-two-column {
    margin-top: 8px;
  }
}

/* For Chrome, Safari, Edge, and Opera */
.create-invoice input[type="number"]::-webkit-outer-spin-button,
.create-invoice input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.create-invoice input[type="number"] {
  -moz-appearance: textfield;
}
@media (max-width: 991.98px) {
  .three-column-row {
    flex-flow: column;
    gap: 15px;
  }
  .three-column-row br {
    display: none;
  }
  .three-column-col {
    width: 100%;
  }
  .page-divider {
    margin: 20px 0;
  }
}
@media (max-width: 575.98px) {
  .ph-link-box {
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 12px;
    flex-flow: column;
  }
  .igr-input,
  .input-two-col {
    width: 100%;
  }
  .igr-input + .igr-input,
  .input-two-col + .input-two-col {
    margin-top: 8px;
  }
}
