table td {
  border-collapse: collapse;
}

p {
  margin: 0;
}

a:hover {
  text-decoration: none !important;
}

.button-hover:hover {
  text-decoration: none !important;
}

b {
  font-weight: 700 !important;
}

strong {
  font-weight: 600 !important;
}

@media screen and (max-width: 567px) {
  .w-full-mobile {
    width: 100% !important;
  }

  .m-block {
    display: block !important;
    width: auto !important;
  }

  .xs-p-15 {
    padding: 15px !important;
  }

  .xs-p-0 {
    padding: 0 !important;
  }

  .m-block.m-pl-0,
  .m-pl-0 {
    padding-left: 0 !important;
  }

  .m-block.m-pt-10 {
    padding-top: 10px !important;
  }

  .mob-block {
    display: block;
    width: auto !important;
  }
}

.w-email-table {
  table-layout: fixed !important;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  background: #f5f5f5;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  -webkit-text-size-adjust: none;
  text-decoration: none !important;
}
