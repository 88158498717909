.listing-page_dropDown_width {
  min-width: 41px !important;
}

.form-control.error {
  border-bottom: solid 1px red;
}

.form-control2.error {
  border: solid 1px red;
}

.Toastify__toast {
  padding: 32px 53px 28px 68px !important;
}

.Toastify__toast-container {
  z-index: 99999999999 !important;
  width: auto !important;
  max-width: 600px !important;
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 0px !important;
}

.alert .alert-close-icon,
.alert .alert-error-fix,
.alert .alert-warning-fix,
.alert .alert-success-icon {
  top: 30px !important;
}

.tooltip-signin-error {
  position: absolute;
  right: 1%;
  top: 20%;
}

.eye-icon-box-top {
  right: 40px !important;
}

.btn-loading.disabled {
  background-color: #47ad1d;
}

.cursor-pointer {
  cursor: pointer !important;
}

.display-hidden {
  display: none !important;
}

.display-block {
  display: block;
}

.listing-page-popOver {
  min-width: 470px;
}

.header-backdrop-fix {
  z-index: 10000;
}

.backdrop-scroll-fix {
  position: fixed;
}

/* ///////////////////////////////  css for crop and zoom  modal*/

.container-edit {
  height: 90vh;
  /* width: 60vw; */
}

.full-screen-disabled {
  visibility: hidden;
}

.full-screen-active {
  visibility: visible;
  object-fit: cover;
}

.container-cropper-edit {
  height: 90%;
  padding: 10px;
  padding-top: 0px !important;
  padding-left: 0px !important;
}

.cropper-edit {
  height: 90%;
  position: relative;
}

.slider-edit {
  height: 10%;
  display: flex;
  align-items: center;
  margin: auto;
  width: 90%;
}

.container-buttons-edit {
  border: 1px solid #f5f5f5;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-range-parent {
  display: flex;
  margin-top: 49px;
  margin-left: 16rem;
}

.slider-edit-name {
  margin-right: 45px;
  font-size: 1rem !important;
}

/* /////////// */

.selected-image {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0 12px 12px 0;
}

.selected-image>img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

.selected-image-closed {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 6px;
  /* line-height: 18px; */
  background: #fff;
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);
  top: -10px;
  right: -10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-disabled {
  opacity: 0.4;
  cursor: unset !important;
  color: white !important;
  pointer-events: none;
}

.link-item {
  color: #47ad1d;
}

.link-item:hover {
  text-decoration: underline;
  color: #47ad1d;
  cursor: pointer;
}

.global-cancel-button,
.global-cancel-button:hover,
.global-cancel-button:active {
  background: #fff !important;
  border: 1px solid white !important;
  color: black;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.btn-yellow,
.btn-yellow:hover {
  background: #f2b61c !important;
  border: 1px solid #f2b61c !important;
}

.btn-red,
.btn-red:hover {
  color: white !important;
  background: #ff4242 !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(80vh - 97px) !important;
}

/* /// */

/* Upload Document Starts */

.upload-documnets-backdrop {
  z-index: 10000 !important;
}

.val-error-text {
  color: red;
  margin-top: 3px;
}

.delete-backdrop.show {
  opacity: 0.6 !important;
  z-index: 999999999;
}

.modal {
  z-index: 1000000000 !important;
}

.upload-images-backdrop.show {
  z-index: 10000 !important;
  opacity: 0.6 !important;
}

.upload-images-edit-backdrop.show {
  z-index: 10000 !important;
  opacity: 0;
}

.ck-editor__editable_inline {
  padding: 0 30px !important;
}

.deafult-black-hover-green {
  color: black !important;
}

.deafult-black-hover-green:hover {
  color: #47ad1d !important;
}

.padding-0 {
  padding: 0px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.ck-content .table {
  display: inherit;
}

.ck-content table {
  width: 100% !important;
}

.ck-content-description {
  overflow-x: auto;
}

.ck-content figure {
  overflow-x: auto;
}

.css-b62m3t-container {
  flex: auto !important;
}

.react-calendar-datetime-picker .input-picker .input-picker--input {
  padding: 8px 12px !important;
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
  z-index: 1300000000 !important;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-rdq5h4-MuiClockPointer-root,
.css-umzx0k-MuiClock-pin,
.css-eg3pzz-MuiClockPointer-thumb,
.css-d0vs79-MuiClockPointer-root,
.css-rjqbug-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton,
.css-h2z9v1-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton {
  background-color: #47ad1d !important;
}

.css-eg3pzz-MuiClockPointer-thumb,
.css-12t0dn4-MuiClockPointer-thumb {
  border: 16px solid #47ad1d !important;
}

.ck-content>table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.8em;
}

.datePicker-text-color {
  border: 1px solid white;
}

.table-overflow-visible {
  overflow-x: visible !important;
}

.css-1mtsuo7 {
  z-index: 1000000000 !important;
}

.css-r7wh8h-control,
.css-3ftui3-control {
  background-color: #f5f5f5 !important;
}

.cropper-face.cropper-crop {
  cursor: none;
}

.parentCrop.cropdiv.cropDivFix {
  pointer-events: none;
}

.tui-image-editor-header-logo,
.tui-image-editor-help-menu.top,
.tui-image-editor-header-buttons,
.tui-image-editor-help-menu.right {
  visibility: hidden !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.linethrough-style {
  text-decoration: line-through;
}

.confirm-modal-index {
  z-index: 10000000000 !important;
}

.confirm-backdrop {
  opacity: 0.6 !important;
  z-index: 9999999999 !important;
}

.search-table-search-box {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  min-width: 250px !important;
}

.seller-motivation-edit:hover {
  color: white !important;
}

.comparable-price-body {
  padding: 0px 30px 30px !important;
}

.text-green {
  color: #47ad1d !important;
}

.previous-seller-motivation-box {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: 20px;
}

.previous-seller-motivation-p {
  margin: 2px;
}

.buyer-logs {
  border-bottom: 1px solid #e4e4e4;
  padding: 5px 0px;
}

.buyer-left-col {
  width: 50%;
  padding: 5px 20px;
}

.seller-motivation-updates {
  max-height: 300px !important;
}

.button-default-hover {
  background-color: #47ad1d !important;
}

.button-default-hover:hover {
  background-color: darkgreen !important;
}

.upload-parts-width {
  min-width: max-content;
}

.form-tick.form-check {
  padding-left: 39px;
  margin: 0;
  text-align: left;
  position: relative;
  min-height: auto;
}

.form-tick .form-check-input:checked {
  background-color: #47ad1d;
  border-color: #47ad1d;
}

.tcbc-text-row.div-comm-notes-sec h1 {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 500;
}

.tcbc-text-row.div-comm-notes-sec h2 {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 500;
}

.ck.ck-balloon-panel {
  z-index: 9999999999999 !important;
}

.ck-content-description#descpFix p,
.ck-content-description#descpFix span,
.ck-content-description#descpFix p span {
  font-size: 0.875rem !important;
}

.hover-chat-link {
  display: block !important;
}

.hover-chat-link div {
  display: none !important;
}

.hover-chat-link:hover .hover-chat-box {
  display: block !important;
  overflow: auto !important;
}

.word-break {
  word-break: break-word !important;
}

.gray-color-text {
  color: rgb(0 0 0 / 50%) !important;
  font-size: 0.8rem !important;
}

.black-color-text {
  color: black !important;
  font-size: 0.8rem !important;
}

.trader-details-expand {
  display: flex !important;
  flex-direction: column !important;
}

.hover-text-green:hover {
  color: #47ad1d !important;
}

body .container-fluid b {
  font-weight: 600;
}

.header-top-bar {
  max-width: 100% !important;
  justify-content: space-between !important;
}

.notes-show-message {
  white-space: pre-wrap !important;
  white-space: pre-line !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
}

.notes-show-message>* {
  line-height: 1.5 !important;
}

.payment-info-main-option {
  margin-bottom: 0.875rem !important;
}

.payment-info-input-margin {
  margin-bottom: 30px !important;
}

.btn.payment-verify-btn {
  margin-left: 0px !important;
  border-left: 1px solid #fff !important;
}

.btn-green-default {
  background: #227300 !important;
  color: #fff !important;
}

.btn-green-default:hover {
  background: #47ad1d !important;
  color: #fff !important;
}

.min-user-table-height {
  min-height: calc(100vh - 259px) !important;
}

.online-member {
  width: 14px;
  height: 14px;
  top: 3px;
}

.m-content {
  padding: 6px 6px 6px 6px;
}

.m-text-xs {
  font-size: 0.75rem !important;
}

.online-member {
  left: 102%;
}

.list-detail-pannel {
  width: auto !important;
}

.message .custom-profile {
  width: 34px;
  height: 34px;
}

.message.received {
  padding-left: 50px;
}

.message.send {
  padding-right: 30px;
}

.pannel-body {
  padding: 25px 0;
}

.empty-pannel-box {
  width: 240px;
  margin: 0 auto;
  text-align: center;
}

.empty-pannel-box>img {
  width: 70px;
  margin: 0 auto 40px;
}

.empty-pannel-box h4 {
  font-size: 1.125rem;
  font-weight: 500 !important;
  line-height: 1;
  color: #000000;
  margin-bottom: 15px;
}

.empty-pannel-box p {
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  opacity: 0.85;
}

.empty-verification-documents {
  margin-top: 45% !important;
}

.mr-64 {
  margin-right: 64px !important;
}

.add-user-flex {
  justify-content: space-between !important;
  /* align-items: center !important; */
}

.add-user-input-width {
  width: 47% !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.gap-user {
  display: flex !important;
  align-items: center !important;
  gap: 79px !important;
}

.width-950 {
  width: 950px !important;
}

.country-margin {
  margin-left: 1px !important;
}

.select-background {
  background: #f5f5f5 !important;
}

.select-disabled-background {
  background: #e9ecef !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.error-text {
  color: red !important;
}

/* customer chat flyers */

.mob-offcanvas-pannel-container .modal-header {
  width: 100%;
  padding: 15px 45px 15px 20px;
  height: 67px;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
}

.mob-offcanvas-pannel-container .modal-body {
  height: calc(100% - 67px);
  background: #fff;
  overflow-y: auto;
  padding: 0;
}

.mob-offcanvas-pannel-container .modal-header h4 {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
}

.mob-offcanvas-pannel-container .modal-header .btn-close {
  opacity: 1;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 65%;
  transform: translateY(-50%);
}

.mob-offcanvas-pannel-container .accordion-item>.accordion-button {
  cursor: pointer;
  height: 70px;
  padding: 15px 15px 15px 20px;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  position: relative;
}

.mob-offcanvas-pannel-container .accordion-item>.accordion-button:not(.collapsed)::after {
  background-image: url(../images/minus.png) !important;
  transition: none;
}

.mob-offcanvas-pannel-container .accordion-item>.accordion-button::after {
  background-image: url(../images/plus.png) !important;
  transform: none;
  width: 24px;
  height: 24px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) !important;
}

.mob-offcanvas-pannel-container .accordion-item>.accordion-button:not(.collapsed) {
  color: var(--black-color);
  box-shadow: none;
  background-color: var(--white-color);
}

.mob-offcanvas-pannel-container .accordion-body {
  padding: 0 25px 25px;
}

.mob-offcanvas-pannel-container .modal-body .accordion-body li a {
  font-size: 0.875rem;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.7);
  width: 100%;
}

.mob-offcanvas-pannel-container .modal-body .accordion-body .all-auctions-btn {
  padding: 0;
  margin-top: 10px;
  color: var(--secondary-color);
  font-size: 0.875rem;
  font-weight: 600;
}

.mob-offcanvas-pannel-container .modal-body .accordion-item {
  border: none;
  border-bottom: 1px solid #e4e4e4;
}

.offcanvas-pannel-container .pannel-header {
  border-bottom: 2px solid #e4e4e4;
  padding: 25px 65px 25px 40px;
  height: 94px;
  position: relative;
}

.offcanvas-pannel-container .pannel-header h4 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;
  color: var(--black-color);
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.offcanvas-pannel-container .pannel-header .btn-close {
  width: 24px;
  height: 24px;
  opacity: 1 !important;
  cursor: pointer;
  position: absolute;
  top: 65%;
  transform: translateY(-50%);
  right: 40px;
  background-image: url(../images/dark_close-icon.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}

.offcanvas-pannel-container .pannel-body {
  width: 100%;
  background: #fff;
  height: calc(100% - 94px);
  overflow-y: auto;
  padding: 30px 0;
}

.empty-offcanvas-pannel .pannel-body .simplebar-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auto-height.offcanvas-pannel-container .pannel-body {
  height: 100% !important;
}

.mob-offcanvas-pannel-container .modal-body .accordion_item .accordion-body li a {
  line-height: 1.4;
}

.offcanvas-pannel-container .pannel-header {
  padding: 15px 30px 15px 20px;
  height: 67px;
  justify-content: center;
}

.offcanvas-pannel-container .pannel-body {
  height: calc(100% - 67px);
  padding: 25px 0;
}

.chat-pannel-header {
  padding: 13px 19.5px;
}

.chat-pannel-body {
  padding: 15px 19.5px 15px;
}

.chat-pannel-body {
  background: #f5f5f5;
  padding: 17px 30px 30px;
  overflow-y: auto;
}

.chat-pannel-body,
.chat-member-widget,
.member-comment-list,
.custom-offcanvas-body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.chat-pannel-body::-webkit-scrollbar,
.chat-member-widget::-webkit-scrollbar,
.member-comment-list::-webkit-scrollbar,
.custom-offcanvas-body::-webkit-scrollbar {
  display: none;
}

.custom-offcanvas-body .message:first-child,
.chat-pannel-body .message:first-child {
  margin-top: auto !important;
}

.message-block-item {
  padding: 15px 85px 15px 40px;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
  cursor: pointer;
}

.notification-block .message-block-item {
  background: #f5f5f5;
}

.notification-block .message-block-item.read {
  background: #fff;
}

.message-block-item {
  padding: 15px 40px 15px 20px;
}

.notification-block .message-block-item {
  background: #f5f5f5;
}

.message-block-item {
  padding: 15px 40px 15px 20px;
}

.has-notify p {
  color: var(--secondary-color) !important;
}

.mb-img-box {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.mb-text-box {
  width: calc(100% - 50px);
  padding-left: 20px;
  font-size: 0.75rem;
  line-height: 1;
}

.mb-text-box h5 {
  font-size: 0.875rem;
  line-height: 1;
  margin-bottom: 10px;
}

.mb-text-box p {
  color: rgba(0, 0, 0, 0.85);
}

.mb-notify-box {
  margin-top: 15px;
  width: 24px;
  height: 24px;
  background: red !important;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  font-size: 0.75rem;
  color: #fff;
  font-weight: 600;
  margin-left: 70% !important;
}

.notify-box {
  right: 15px;
}

.notify-box {
  position: absolute;
  right: 40px;
  top: 15px;
  transform: none;
}

.mb-days-box {
  font-size: 0.75rem;
  line-height: 1;
  color: rgb(0 0 0 / 50%);
}

.chatbox-header {
  /* height: 94px; */
  /* border-bottom: 2px solid #e4e4e4; */
  padding: 25px 40px 25px 20px;
  position: relative;
}

.chatbox-header .dropdown-toggle {
  cursor: pointer;
}

.chatbox-header .btn-close {
  width: 24px;
  padding: 0;
  position: absolute;
  right: 40px;
  top: 65%;
  transform: translateY(-50%);
  cursor: pointer;
}

.chatbox-body {
  height: calc(100% - 167px);
}

.chatbox-header .back-btn {
  padding-left: 0;
  left: 0;
}

.ch-member-box {
  padding-left: 35px;
}

.chatbox-header .back-btn {
  padding-left: 0;
  left: 0;
}

.cmb-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.cmb-img>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat-top-bar .chatbot-right {
  cursor: pointer;
}

.chatbox-body {
  height: calc(100% - 194px);
  padding: 40px 20px;
  background: #f5f5f5 !important;
}

.chatbox-body {
  height: calc(100% - 167px);
}

/* message class */

.message-block-item {
  padding: 15px 85px 15px 40px;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
  cursor: pointer;
}

.chatbox-body .simplebar-content {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.message1.send1 {
  width: 50%;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  align-self: flex-end !important;
  padding-left: 15px;
}

.message1.send1 .content {
  padding: 9px 17px;
  background: #ffffff;
  border-radius: 4px 4px 0px 4px;
  font-size: 0.875rem;
  line-height: 140%;
}

.message1.received1 .content {
  padding: 9px 17px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), #a1cf6d;
  border-radius: 4px 4px 4px 0px;
  font-size: 0.875rem;
  line-height: 140%;
}

.message1.received1 {
  width: 80%;
  padding-right: 15px;
}

.send1+.received1,
.received1+.send1 {
  margin-top: 54px;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1;
  color: rgb(0 0 0 / 50%);
  width: 100%;
  margin-top: 7px;
}

.send1 .text-xs {
  text-align: right;
}

.message1 .author {
  white-space: nowrap;
  align-items: center;
  margin-bottom: 7px;
}

.message1 .author .author-name {
  margin-bottom: 0;
}

.message1 .author .text-xs {
  margin-top: 0;
}

textarea#message1 {
  resize: none;
  height: 220px;
  background-color: transparent;
  border-radius: 4px;
  padding: 24px 23px;
  font-size: 0.875rem;
}

.message1.send1 {
  padding-right: 67px;
}

.message1.received11 {
  padding-right: 0;
  padding-left: 67px;
}

.message.send1+.message1.received1 {
  margin-top: 20px;
}

.message1.send1 .m-text-xs {
  justify-content: flex-end !important;
}

.message1.send1 {
  align-self: flex-end !important;
  align-items: flex-end !important;
}

.message1.received1 .content {
  padding: 9px 17px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), #a1cf6d;
  border-radius: 4px 4px 4px 0px;
  font-size: 0.875rem;
  line-height: 140%;
}

.message1.received1 {
  width: 80%;
  padding-right: 15px;
}

.send1+.received1,
.received1+.send1 {
  margin-top: 54px;
}

.received1 .m-profile-icon {
  right: auto !important;
  left: 0 !important;
}

.message1.send1 {
  padding-right: 67px;
}

.message1.send1+.message1.received1 {
  margin-top: 20px;
}

.message1.received1 {
  width: 50%;
}

.message1.send1 {
  width: 70%;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  align-self: flex-end !important;
  padding-left: 15px;
}

.message1.send1 .content {
  padding: 9px 17px;
  background: #ffffff;
  border-radius: 4px 4px 0px 4px;
  font-size: 0.875rem;
  line-height: 140%;
}

.send1 .text-xs {
  text-align: right;
}

.message1.send1 {
  padding-right: 0px;
}

.message1.received1 {
  padding-right: 0;
  padding-left: 0px;
}

.message1.send1+.message1.received1 {
  margin-top: 20px;
}

.message1.send1 .m-text-xs {
  justify-content: flex-end !important;
}

.chatbox-footer {
  padding: 25px 30px 25px 40px;
  height: 100px;
  border-top: 2px solid #e4e4e4;
}

.chat-box-right .chatbot-wapper .chatbox-footer {
  background-color: var(--white-color);
  height: 80px;
  padding-right: 27px;
}

.chat-box-right .chatbot-wapper .chatbox-footer .input-group-text {
  background-color: transparent;
}

.chat-box-right .chatbot-wapper .chatbox-footer .custom-form-control {
  border-radius: 10px;
  padding: 12px 50px 12px 24px;
}

.chat-box-right .chatbot-wapper .chatbox-footer .input-group-append1 {
  right: 0;
  height: 100%;
  background: #47ad1d;
  border-radius: 0 10px 10px 0;
}

.chatbox-footer {
  padding: 15px 20px;
}

.input-group-prepend {
  margin-right: 25px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cc-bottom .input-group-prepend .input-group-text {
  border: 0;
  border-radius: 10px;
  background: transparent;
  padding: 20px;
}

.input-group-prepend {
  margin-right: 20px;
}

.input-group-text {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
}

.form-box .input-group-text {
  padding: 0.375rem 0.75rem;
}

.nh-search .input-group-text {
  position: relative;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1;
  color: #ffffff;
  border-radius: 0;
}

.chat-box-right .chatbot-wapper .chatbox-footer .input-group-text {
  background-color: transparent;
}

.custom-form-control {
  width: auto;
  border-radius: 10px;
  background: #f5f5f5;
  border: 0;
  padding: 10px 50px 10px 24px;
}

.custom-form-control input {
  width: 100%;
  background: transparent;
  border: 0;
  outline: 0;
  font-size: 0.875rem;
  line-height: 1;
}

.custom-form-control textarea {
  width: 100%;
  background: transparent;
  border: 0;
  outline: 0;
  font-size: 0.875rem;
  line-height: 1;
}

.input-group-append1 {
  width: 44px;
  cursor: pointer;
  height: 100%;
  background: #47ad1d;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.nh-search .input-group-append1 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: calc(100% - 2px);
  background: #47ad1d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.input-group-append1 img {
  margin: 0 4px -4px 0px;
}

.message1.send1.send-messages {
  margin-left: 50% !important;
  width: 50% !important;
}

.mb-img-box.user-img-box {
  border-radius: 50% !important;
}

.sender-img-chat {
  min-width: fit-content !important;
  width: 34px;
  height: 34px;
}

.cursor-auto {
  cursor: auto !important;
}

.link-item-text {
  color: black !important;
}

.link-item-text:hover {
  color: #47ad1d !important;
}

.m-text-small {
  color: rgb(0 0 0 / 80%) !important;
}

/* chat section ended */

/* =============================== Dashboard Collapse Start ============================ */

.offcanvas-backdrop {
  z-index: 9999 !important;
}

.dashboard-insider {
  background-color: #fff;
}

.main-container {
  width: auto;
  flex-grow: 1;
}

.side-nav {
  width: 86px;
  transition: 0.2s all ease;
  z-index: 1052;
  position: sticky !important;
  height: calc(100vh - 80px) !important;
  top: 80px !important;
}

.open-menu .side-nav {
  overflow: hidden;
}

.side-nav:after {
  content: "";
  width: 1px;
  height: 100%;
  background: #e4e4e4;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
}

.side-nav .profile-img {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0;
  position: absolute;
  left: 17px;
  top: 5px;
}

.toggler-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  z-index: 1;
  top: 50px;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateX(25px);
  transition: 0.2s all ease;
}

.toggler-btn svg {
  transform: rotate(-180deg);
}

.open-menu .toggler-btn svg {
  transform: rotate(0deg);
}

.toggler-btn svg {
  stroke: black;
}

.open-menu .toggler-btn {
  background: #227300;
  transform: translateX(0px);
}

.open-menu .toggler-btn svg {
  stroke: #fff;
}

.profile-area {
  position: relative;
  padding: 15px 0px 15px 70px;
  height: 60px;
  white-space: nowrap;
  background: #227300;
  cursor: pointer;
}

.menu-toggle-area {
  height: 85px;
  background: #227300;
}

.profile-text-box h4 {
  font-size: 0.875rem;
  line-height: 150%;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
}

.side-nav-inside .accordion-item:first-of-type .accordion-button,
.side-nav-inside .accordion-item:last-child .accordion-button {
  background: transparent !important;
}

.side-nav-inside .accordion-header {
  padding: 20px;
  position: relative;
}

.accordion-item.dropdown .accordion-header {
  cursor: pointer;
}

.side-nav-inside .accordion-button {
  font-size: 0.875rem;
  padding: 0px 12px 0px 70px;
  font-weight: 500;
  width: 86px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 0.2s all ease;
  color: var(--black-color);
}

.dropdown .accordion-button:before {
  content: "";
  z-index: 1;
  width: calc(100% - 16px);
  height: 100%;
  background: rgb(71 173 29 / 10%);
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
}

/* .side-nav-inside .accordion-button svg path {
  opacity: 0.4;
} */

.open-menu .side-nav-inside .accordion-button {
  width: 303px;
}

.side-nav-inside .accordion-item+.accordion-item {
  margin-top: 4px;
}

.profile-text-box {
  clip-path: inset(0 100% 0 0);
}

.side-nav-inside .accordion-button:not(.collapsed) {
  background-color: transparent;
}

.side-nav-inside .accordion-button::after {
  position: absolute;
  right: 25px;
  background-size: 14px !important;
}

.side-nav-inside .dropdown-icon svg,
.side-nav-inside svg {
  width: 25px;
  stroke: black;
  fill: none;
}

.side-nav-inside .accordion-item:first-child .accordion-button:after,
.side-nav-inside .accordion-item:last-child .accordion-button:after {
  background: none !important;
}

.side-nav-inside .accordion-item:first-child .accordion-button,
.side-nav-inside .accordion-item:last-child .accordion-button {
  color: var(--black-color);
}

.accordion-item.dropdown .dropdown-icon {
  cursor: pointer;
}

.side-nav-inside .dropdown-icon.show {
  background: #f5f5f5 !important;
}

.side-nav-inside .dropdown-icon {
  position: absolute;
  z-index: 1;
  width: 85px;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  clip-path: inset(0 100% 0 0);
}

.side-nav-inside .accordion-button span {
  clip-path: inset(0 100% 0 0);
  transition: 0.1s all ease-in;
}

.dropdown-icon>svg {
  position: absolute;
  top: 18px;
  left: 30px;
}

.accordion-button svg {
  position: absolute;
  left: 30px;
  transform: none;
  top: 8px;
}

.active .accordion-button svg {
  stroke: #227300;
}

.side-nav-inside .accordion-item {
  position: relative !important;
  border-radius: 0 !important;
  border-color: #e4e4e4;
  border-width: 0;
}

.side-nav-inside .accordion-item:last-child {
  border-bottom: 0;
}

.side-nav-inside .dropdown-menu {
  min-width: 260px;
  left: 100% !important;
  top: 0 !important;
  border-radius: 0;
  transform: none !important;
  height: 100%;
  box-shadow: 4px 0 5px -2px rgba(0, 0, 0, 0.15) !important;
  z-index: 99;
}

.side-nav-inside .dropdown-menu .accordion-body {
  padding: 5px 0px;
}

.side-nav-inside .accordion-button.collapsed::after {
  transform: rotate(-180deg) !important;
}

.side-nav-inside .accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.side-nav-inside .accordion-button::after {
  transform: rotate(0deg);
}

.side-nav-inside .accordion-body ul li>a {
  font-size: 0.875rem;
  color: #000;
  padding: 4.5px 48px 4.5px 73px;
  width: 100%;
  font-weight: 500;
  position: relative;
}

.side-nav-inside {
  height: calc(100% - 80px);
  background: #fff;
  padding: 22px 0 50px;
}

.side-nav-inside>.accordion {
  height: 100%;
}

.side-nav-inside .dropdown-menu .accordion-body ul li:hover {
  background: transparent;
  color: #227300 !important;
}

.side-nav-inside .dropdown-menu .accordion-body ul li .active {
  background: transparent;
  color: #227300 !important;
  font-weight: 500;
}

.side-nav-inside .accordion-body ul li>a:hover {
  color: #227300 !important;
}

.side-nav-inside .accordion-item .accordion-button .chevron-down-icon {
  stroke: #000 !important;
  position: absolute !important;
  right: 28px !important;
  left: auto !important;
  display: none !important;
  right: 6px !important;
  top: 10px !important;
  transform: rotate(-90deg);
  transition: 0.2s all ease-in;
}

.side-nav-inside .accordion-item.dropdown .accordion-button .chevron-down-icon {
  display: block !important;
}

.side-nav-inside .accordion-button:not(.collapsed)::after,
.side-nav-inside .accordion-button::after {
  content: none !important;
}

body:not(.open-menu) .side-nav-inside .accordion-body ul li>a {
  line-height: 2.5;
  padding: 9px 25px !important;
}

body:not(.open-menu) .side-nav-inside .accordion-item {
  position: static !important;
}

.open-menu .side-nav-inside .accordion-item .accordion-button .chevron-down-icon {
  right: 28px !important;
  top: 25px !important;
  transform: none !important;
}

.open-menu .profile-text-box {
  clip-path: inset(0 0 0 0);
}

.open-menu .side-nav-inside .accordion-button span {
  clip-path: inset(0 0 0 0);
  transition: 0.1s all ease-in;
}

.open-menu .side-nav {
  width: 303px;
}

.open-menu .side-nav-inside .accordion-body {
  padding: 8px 0 0 0;
}

.open-menu .side-nav-inside .accordion-collapse {
  position: relative !important;
  left: auto !important;
  top: auto !important;
  transform: none !important;
}

.open-menu .screen-darken {
  display: none !important;
}

.open-menu .side-nav-inside .accordion-item .accordion-button .chevron-down-icon,
.side-nav-inside .accordion-item .show .accordion-button .chevron-down-icon {
  stroke: #000 !important;
}

.open-menu .side-nav-inside .accordion-item .accordion-button .chevron-down-icon {
  top: 11px !important;
}

.open-menu .side-nav-inside .accordion-body ul li>a {
  color: rgb(0 0 0 / 70%) !important;
}

.open-menu .side-nav-inside .accordion-button span+svg path {
  opacity: 1;
}

.side-nav-inside .accordion-body ul li>a::before {
  content: "";
  z-index: 1;
  width: calc(100% - 16px);
  height: 100%;
  background: rgb(71 173 29 / 10%);
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
}

.side-nav-inside .accordion-body ul li>a:hover::before {
  opacity: 1;
  visibility: visible;
}

.side-nav-inside .accordion-body ul li>a:hover {
  color: #47ad1d !important;
}

.side-nav-inside .accordion-body ul li+li {
  margin-top: 1px;
}

.open-menu .side-nav-inside .accordion-body ul li>a.active {
  color: #47ad1d !important;
  font-weight: 500;
}

.open-menu .accordion-button.side-nav-accordion-button[aria-expanded="true"]::before {
  opacity: 1;
  visibility: visible;
}

.open-menu .accordion-button.side-nav-accordion-button[aria-expanded="true"] {
  color: #47ad1d;
}

.open-menu .side-nav-inside .accordion-button[aria-expanded="true"] svg path {
  opacity: 1;
}

.side-nav-inside .accordion-header.show .accordion-button {
  background: transparent;
}

.side-nav-inside .accordion-header.show .accordion-button:before {
  opacity: 1;
  visibility: visible;
}

.side-nav-inside .accordion-header.show .accordion-button svg path {
  opacity: 1;
  stroke: #47ad1d;
}

/* =============================== Dashboard Collapse End ============================ */

.main-menu-expanded {
  width: calc(100% - 303px) !important;
  margin-left: auto !important;
}

.main-menu-compressed {
  width: calc(100% - 86px) !important;
  margin-left: auto !important;
}

.listing-page-padding-top {
  padding-top: 151px !important;
}

.img-svg {
  position: absolute;
  left: 30px !important;
  top: 8px !important;
}

.active-menu {
  color: #47ad1d !important;
}

.open-menu .side-nav-inside .accordion-body ul li>.active-menu {
  color: #47ad1d !important;
}

.payables-container tr .payables-label {
  padding-top: 10px !important;
}

.method-z-index {
  z-index: 9999999999999999999 !important;
}

.btn-push-email {
  min-width: 130px !important;
}

.input-width-50 {
  width: 40% !important;
}

.cancel-user-pref {
  margin-left: 30rem !important;
}

.header-buyer-pref {
  padding-left: 1.5rem !important;
}

.toast-link-msg {
  cursor: pointer !important;
  color: #47ad1d !important;
  text-decoration: underline !important;
}

.dropdown-item {
  padding: 0.5rem 1rem !important;
}

.aviation-filter-fix {
  padding-left: 25px !important;
}

.tr-disabled {
  background-color: #efefef;
  color: rgba(0, 0, 0, 0.5) !important;
}

.tr-top3 {
  background-color: #f6dc60;
}

.aviation-filter-fix {
  padding-left: 29px !important;
}

.aviation-filter-fix .offcanvas-body {
  padding-top: 0px;
}

.filter-investment {
  max-width: 500px !important;
  margin-bottom: 40px !important;
}

.listing-container-investment {
  width: calc(100% - 12px) !important;
  max-width: calc(100% - 12px) !important;
  min-width: calc(100% - 12px) !important;
}

.investment-title-left {
  margin-left: -11px !important;
}

.aviation-auction:hover {
  cursor: pointer !important;
  color: #47ad1d !important;
  text-decoration: underline !important;
}

.aviation-view-modal>span,
.aviation-view-modal1>span {
  width: 33.3% !important;
}

.aviation-view-modal1>span {
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.aviation-view-modal>span {
  color: #000;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
}

.aviation-view-modal>span {
  margin-bottom: 15px !important;
  margin-left: 8px;
}

.aviation-view-modal1>span {
  margin-bottom: 25px !important;
  overflow-wrap: break-word;
  margin-left: 8px;
}

.aviation-view-body {
  padding-left: 30px !important;
  padding-right: 30px !important;
  max-height: 500px;
  overflow-y: scroll;
}

.edit-select-aviation {
  width: 93% !important;
}

.form-control-aviation {
  width: 93% !important;
}

.react-datepicker-wrapper {
  display: block !important;
  width: 93% !important;
}

.react-datepicker__day--selected {
  background-color: #47ad1d !important;
}

.react-datepicker__header {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(90.69deg, #007541 -35.09%, #58bb44 117.16%);
}

.emp-inv-table {
  max-width: 100%;
  max-height: 800px;
  overflow: auto;
}

.emp-inv-table thead tr .years-tab {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 0;
  z-index: 2;
}

.emp-inv-table thead .inv-labels th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 51.5px;
  z-index: 2;
}

.emp-inv-table tbody th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  left: 0;
  z-index: 2;
}

.emp-inv-table thead .employee {
  position: sticky;
  top: 0px;
  left: 0;
  z-index: 5;
}

.emp-inv-table thead th {
  z-index: 1;
}

.emp-inv-table thead th {
  background: #f5f5f5;
  z-index: 1;
}

.emp-inv-table tbody th {
  background: #fff;
  border-right: 1px solid #ccc;
  box-shadow: 1px 0 0 0 #ccc;
}

.emp-inv-table table {
  border-collapse: collapse;
}

.emp-inv-table td,
.emp-inv-table th {
  padding: 0.5em;
}

.investment-filter {
  z-index: 9999999999 !important;
}

.sort-active:before {
  border-bottom: 6px solid #47ad1d !important;
}

.sort-active1:after,
.sort-active:after {
  border-top: 6px solid #47ad1d !important;
}

.active-column {
  color: #47ad1d !important;
}

.deal-progress-type-heading {
  font-size: 0.875rem !important;
}

.deal-body {
  width: 100% !important;
}

.deal-column {
  width: 100% !important;
  flex-wrap: wrap !important;
  margin-top: 15px !important;
}

.deal-column-body {
  width: 100% !important;
  margin-right: 5px;
}

.deal-box {
  width: 95%;
  display: block;
  text-align: center;
  padding: 5px 3px;
  background-image: linear-gradient(180deg, #efefef, #bbbbbb);
  line-height: 14px;
  border-bottom: 1px solid #fff;
  margin-top: 4px;
}

.deal-box-text {
  color: #444;
  font-size: 12px !important;
}

.deal-body-heading {
  font-size: 13.5px !important;
  min-height: 8px;
}

.ytd {
  background-image: linear-gradient(180deg, #b5f77a, #88c94e);
  color: #326901;
}

.ntd {
  color: #444;
}

.rtd {
  background-image: linear-gradient(180deg, #f5b4b5, #ee9191);
  color: red !important;
}

.neglected-deal {
  background: red !important;
  color: #fff !important;
}

.yellowtd {
  background: yellow;
  color: #666600;
}

.error-page-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.error-content-box {
  width: 100%;
  max-width: 248px;
  margin: 0 auto;
}

.error-content-box h6 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 35px;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--black-color);
  opacity: 0.85;
}

.four-zero-four-img {
  width: 248px;
  margin: 0 auto 30px;
}

.error-content-box p {
  font-size: 1rem;
  line-height: 1;
  color: rgb(0 0 0 / 85%);
}

.error-content-box a.btn {
  margin-top: 35px;
  min-width: 150px;
}

.contracts-bottom-border tr:not(:last-child) {
  padding: 0.5rem 0.5rem !important;
  border-bottom: 1px solid #dee2e6 !important;
}

table.table2.contracts-bottom-border tr td {
  padding: 0.5rem 0.5rem !important;
}

.h-42 {
  height: 42px !important;
}

.add-contract-calendar .react-datepicker-wrapper {
  width: 100% !important;
}

.contract-type-dropdown {
  margin-right: 2px !important;
}

.proc-logo-contract {
  width: 100px !important;
  height: 100px !important;
  margin-left: 10px !important;
}

.contract-drpdown-error {
  margin-top: -28px !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__navigation {
  color: #fff !important;
}

.filter-user-input-width .react-datepicker-wrapper {
  margin-right: 0px !important;
  width: 100% !important;
}

.customized-drpdown-btn:active {
  background-color: #47ad1d !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #47ad1d !important;
}

.container-fluid.listing-container .listing-page-width-fix {
  min-width: 94% !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.contract-listings-item tbody tr {
  display: block !important;
  margin-bottom: 20px !important;
}

.link-item-contract-listing {
  color: #fff !important;
}

.link-item-contract-listing:hover {
  color: #fff !important;
  text-decoration: underline;
}

.seller-agreement-container {
  width: 60vw;
  max-width: 1024px;
  margin: 0vh 20vw 16vh 20vw;
  background-color: #f5f5f5;
}

.sa_logo {
  padding-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sa_heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sa_heading h1 {
  margin: 25px auto;
  font-size: 35px;
  line-height: 22px;
  font-family: Arial, sans-serif;
  display: inline-block;
}

.sa_content {
  padding: 15px 41px 5px 41px;
}

.sa_content p:first-child {
  font-size: 20px;
  line-height: 1.6;
  font-weight: 400;
  font-family: Arial, sans-serif;
}

.sa_bold {
  font-weight: 700;
}

.sa_condition {
  padding: 5px 25px;
}

.sa_content .sa_condition li,
.sa_seller_details_container span {
  font-size: 18px;
  line-height: 1.4;
  font-family: Arial, sans-serif;
}

.sa_seller_details_container {
  padding: 7px 41px;
}

.sa_seller_details_container h5 {
  font-size: 21px;
  text-decoration: underline;
  font-weight: 500;
  line-height: 1.6;
  font-family: Arial, sans-serif;
}

.sa_mr-6 {
  margin-right: 20px;
}

.sa_details {
  margin-bottom: 15px;
}

.sa_preview_btn {
  background-color: #227300 !important;
}

.sa_preview_btn:hover {
  background-color: #47ad1d !important;
  text-decoration: none;
}

.sa_agree_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 70px;
}

.av_viewall_modal {
  z-index: 9999999999999999 !important;
}

.accordion-body-header {
  background-color: #fff;
  min-width: 220px;
  max-width: fit-content;
  height: fit-content;
  list-style: none;
  padding-left: 18px;
  box-shadow: 4px 0 5px -2px rgba(0, 0, 0, 0.15) !important;
}

.allOther-td {
  color: #8e3a08 !important;
}

.pdc-head-rights {
  width: calc(100% - 60px);
  padding-left: 30px;
}

.pdc-head-rights h6 {
  font-weight: 500;
  font-size: 1rem;
  line-height: 160%;
  margin-bottom: 0;
}

.assignment-td {
  min-width: auto;
  min-height: auto;
  max-width: 100px;
  max-height: 80px;
  border-radius: 4px;
  overflow: hidden;
}

.assignment-td>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-activity-width {
  width: 49% !important;
}

.activity-emp-details {
  margin-left: 40px;
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 16px;
}

.badge-box {
  padding: 0px 5px !important;
  border-radius: 12px !important;
  font-weight: 500 !important;
}

.badge-list {
  padding-top: 13px !important;
}

.listing-label-bar {
  margin-left: 85px !important;
}

.detail-menu {
  box-shadow: 0px 1px 0px #ebebeb !important;
  height: 60px !important;
}

.h-60 {
  height: 60px !important;
}

.more-default-btn,
.more-default-btn:focus {
  color: #47ad1d !important;
  background-color: #fff;
  border: solid 1px #47ad1d;
}

.more-default-btn:hover,
.more-default-btn:active {
  color: #fff !important;
  background-color: #47ad1d;
  border-color: #227300;
}

.more-selected-btn,
.more-selected-btn:focus {
  color: #fff !important;
  background-color: #47ad1d;
  border: solid 1px #fff;
}

.more-selected-btn:hover,
.more-selected-btn:active {
  color: #47ad1d !important;
  background-color: #fff;
  border-color: #47ad1d;
}

.bg-major-listing-green {
  background: #47ad1d !important;
  color: #fff !important;
  font-weight: 500;
}

.w-40 {
  width: 45% !important;
}

.w-60 {
  width: 55% !important;
}

.legend_customer {
  padding: 22px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.customer_grid_loaded {
  padding: 10px 0px !important;
  color: white !important;
  background: green !important;
}

.filter-customers {
  width: 40%;
  display: flex;
  align-items: center;
}

.mail-icon-svg {
  height: 30px !important;
  width: 30px !important;
}

.bold {
  font-weight: 600;
}

.env_box {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.env_box div {
  padding: 20px;
  margin-bottom: 20px;
  width: 47% !important;
  border: 1px solid #efefef;
}

.env_box p {
  margin-top: 10px;
  font-weight: 600;
  padding: 0px 6px;
}

.modal-wide {
  max-width: 1600px !important;
}

.gray-color-text-xl {
  padding-top: 5px;
  font-size: 0.875rem !important;
}

.aviation-viewall-table>tr {
  border-top: 20px solid white !important;
  border-bottom: 20px solid white !important;
}

.aviation-viewall-table>tr>td {
  min-width: 5%;
  width: 5%;
  vertical-align: middle;
}

.aviation-viewall-table> :not(caption)>*>* {
  padding: 0;
}

.w-350 {
  max-width: 350px !important;
}

.w-900 {
  width: 900px;
  max-width: 900px;
}

.ml-15 {
  margin-left: 15px !important;
}

.av_viewall_modal1 {
  z-index: 99999999999 !important;
}

.mr-20 {
  margin-right: 130px !important;
}

.hover-chat-box {
  display: none;
}

.hover-chat-link:hover+.hover-chat-box {
  display: block;
  background-color: white;
  width: 300px;
  padding: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 999;
}

.highlight-header {
  background-color: #f5f5f5 !important;
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.link-btn {
  color: #47ad1d !important;
  font-size: 15px !important;
  margin-left: 20px !important;
  margin-right: 20px;
}

.link-btn:hover {
  color: #47ad1d !important;
  text-decoration: underline;
  cursor: pointer;
}

.ml-0 {
  margin-left: 0px !important;
}

.small-thumbnail {
  width: 36px !important;
  margin-right: 20px !important;
}

.div-comments-message a,
.div-comments-message>a {
  color: #47ad1d !important;
  cursor: pointer !important;
}

.div-comments-message a:hover,
.div-comments-message>a:hover {
  color: #47ad1d !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.sticky-top {
  top: 158px !important;
}

.side-nav {
  top: 0px !important;
}

.badge-list {
  width: auto !important;
  margin-bottom: 0px !important;
}

.pl-pr-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.product-table tr td,
.horizontal-table tr td,
.horizontal-table tr th {
  padding: 5px !important;
  font-size: 0.75rem !important;
}

.sticky-listing-top {
  z-index: 1020 !important;
  position: sticky !important;
  top: -1px !important;
  right: auto !important;
}

.startegic-team-badge {
  display: inline !important;
  font-size: 13px !important;
  margin-left: 0px !important;
}

.edit-strategic-team {
  display: inline !important;
  margin-left: 20px !important;
}

select option {
  padding-left: 10px !important;
}

.custom-dropdown-menu {
  margin-top: -1rem !important;
  margin-left: -1rem !important;
}

/* Accordion */
.accordion-widget-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.6;
}

.dropdown-menu.no-upcoming-bids-dropdown {
  right: 0 !important;
  left: auto !important;
  min-width: 270px !important;
}

.no-upcoming-bids-content {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}

.accordion-widget-item {
  padding: 30px;
}

.accordion-widget-item+.accordion-widget-item {
  border-top: 1px solid #e4e4e4;
}

.accordion-button-insider {
  font-size: 12px;
  line-height: 1.6;
  font-weight: 400;
  color: #fff;
}

.accordion-button-insider h3 {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0;
}

.accordion-button-insider h3+p {
  opacity: 0.9;
  margin-top: 6px;
}

.accordion-widget-title+.accordion {
  margin-top: 10px !important;
}

.new-custom-accor .accordion-item {
  border: 0;
}

.new-custom-accor .accordion-item+.accordion-item {
  margin-top: 10px;
}

.new-custom-accor .accordion-button::after {
  background-image: url(../images/plus-circle-1.svg) !important;
  position: absolute;
  top: 17px;
  left: 22px;
}

.new-custom-accor .accordion-button:not(.collapsed)::after {
  background-image: url(../images/minus-circle-1.svg) !important;
  transform: none !important;
}

.new-custom-accor .accordion-button {
  border: 1px solid transparent;
  border-bottom: 0 !important;
  background: linear-gradient(0deg, #47ad1d, #47ad1d),
    linear-gradient(0deg, rgba(245, 245, 245, 0.6), rgba(245, 245, 245, 0.6)), #ffffff;
  border-radius: 4px;
  color: #fff;
  padding: 15px 30px 15px 62px;
}

.new-custom-accor .accordion-item:first-of-type .accordion-button,
.new-custom-accor .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 4px;
}

.new-custom-accor .accordion-button:not(.collapsed) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: #e4e4e4;
  background: linear-gradient(0deg, #376830, #376830), #ffffff;
}

.new-custom-accor .accordion-body {
  border: 1px solid #e4e4e4;
  border-top: 0;
  padding: 20px;
  background: linear-gradient(0deg, rgba(245, 245, 245, 0.6), rgba(245, 245, 245, 0.6)), #ffffff;
}

.accor-table-label {
  font-size: 0.75rem;
  line-height: 1;
  color: #47ad1d;
  background: #fff;
  white-space: nowrap;
  display: inline-block;
  padding: 6px 10px;
  font-weight: 500;
  border: 1px solid currentColor;
  border-radius: 4px;
}

.accordion-button-insider h3 {
  font-weight: 500 !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.enterprise-contract .react-datepicker-wrapper {
  width: 100% !important;
}

.status-margin {
  margin-right: 4px !important;
}

.new-custom-accor .accordion-body {
  background: #fff !important;
}

.sticky-listing-top.filter-container {
  height: 100px !important;
}

.badge-item {
  font-size: 12px !important;
  padding: 3px !important;
  list-style: none;
  margin-bottom: 0px !important;
}

.bids-text {
  font-weight: 600 !important;
  font-size: 15px !important;
}

.bid-rating .me-2 {
  margin-right: 3px !important;
  font-size: 10px !important;
}

.bid-rating {
  padding-right: 0px !important;
}

.top-bids-table tr td {
  vertical-align: top !important;
}

.top-bids-icon {
  margin-right: 3px !important;
  margin-top: 7px !important;
}

.top-bids-icon img {
  width: 20px !important;
  height: 20px !important;
}

.space-between {
  justify-content: space-between;
}

.bg-blue {
  background-color: rgb(16, 75, 124) !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.other-deal-container {
  padding: 16px 10px !important;
}

.svg-more-icon {
  width: 20px;
  height: 30px;
}

.primary-seller-header .nav-item .nav-link {
  font-size: 10px !important;
}

.enterprise-logo-img {
  max-width: 260px !important;
  max-height: 40px !important;
}

.old-admin-btn {
  position: absolute !important;
  right: 50px !important;
  bottom: 17px !important;
}

.large-table1 tr td:nth-child(2) {
  text-align: left !important;
}

.disabled-buyer-interest {
  background-color: #ccc;
  opacity: 0.5;
  pointer-events: none;
}

.view-history {
  font-size: 0.75rem !important;
  margin: 0px !important;
}

.sml-link {
  line-height: 60px !important;
}

.dpc-col-column-custom1 {
  width: 66.6% !important;
  padding: 6px !important;
}

.dpc-col-column-custom2 {
  width: 33.3% !important;
  padding: 6px !important;
}

.dpc-col-column-half {
  width: 50% !important;
  padding: 6px !important;
}

.top-bids-parent .tbr-item-txt {
  font-size: 0.75rem !important;
}

.history-bids tr td,
.history-bids thead tr th {
  padding: 5px !important;
}

.rsp {
  background-color: red !important;
  color: #fff;
}

.ysp {
  background-color: yellow !important;
  color: #000;
}

.gsp {
  background-color: green !important;
  color: #fff;
}

.d-ysp {
  background-color: #ffc300 !important;
  color: white;
}

.greysp {
  background-color: gray !important;
  color: white;
}

.comments-notes-parent {
  padding-top: 3px !important;
  padding-bottom: 0px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.form-checkbox-item-receipt {
  padding: 5px !important;
}

.form-check-receipt {
  padding-left: 25px !important;
}

.container-titlebar {
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.send-btn-notes {
  min-width: 64px !important;
}

.add-more {
  margin-left: 0px !important;
  text-decoration: underline !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.table-custom thead th tr td,
.table-custom thead tr td,
.table-custom thead th td {
  font-weight: 600 !important;
}

.large-table thead tr {
  background: #f5f5f5;
}

.ml-auto,
.react-datepicker-wrapper.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.cp-member .online-member {
  top: 50% !important;
  left: 100% !important;
  transform: translate(5px, -50%) !important;
}

.container-fluid.listing-container .row {
  max-width: none !important;
}

#inputGroupFile02dk[type="file"] {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  width: 95px;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.chat-media-item {
  background: #d6d6d6 !important;
  height: auto !important;
}

.chat-media-list {
  display: flex !important;
  flex-wrap: wrap !important;
  padding: 10px 30px !important;
}

.chat-images {
  margin: 6px 10px !important;
}

.media-into span {
  max-width: 100px !important;
}

.btn-disabled {
  cursor: auto !important;
}

.rdr-form-box {
  width: calc(100% - 184px) !important;
  font-size: 0.75rem;
}

.cat-margin-top {
  margin-top: 25px !important;
}

.google-analytic-heading {
  min-width: 160px !important;
  margin-right: 20px !important;
}

.google-analytic-select {
  min-width: 150px !important;
}

.google-analytics .react-datepicker-wrapper {
  width: 100% !important;
}

.qty-pricing-font {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.email-report-card {
  display: inline-block !important;
  border-radius: 20px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.btn-accepted {
  background-color: #f2b61c !important;
  border-color: white !important;
  color: white !important;
  cursor: auto !important;
  pointer-events: none;
}

.listing-container-listing {
  width: calc(100% - 33px) !important;
}

.page-row {
  width: 100% !important;
}

.ck.ck-dropdown .ck-dropdown__arrow {
  z-index: 0 !important;
}

.page-top-wrapper {
  z-index: 999 !important;
}

.key-push-btn {
  min-width: fit-content;
  padding: 6px 12px;
  font-size: 0.65rem !important;
  color: #fff !important;
  background-color: #227300;
  border: solid 1px #227300;
}

.key-push-btn:focus {
  color: #fff !important;
  background-color: #227300;
  border: solid 1px #227300;
}

.key-push-btn:hover,
.key-push-btn:active {
  color: #fff !important;
  background-color: #47ad1d;
  border-color: #47ad1d;
}

.mt-10 {
  margin-top: 10px !important;
}

.email-marketing-container {
  width: 75vw;
  max-width: 1024px;
  margin: 0vh 14vw 14vh 18vw;
  background-color: #f5f5f5;
  padding-bottom: 8px;
}

.send-marketing-header {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-marketing-body {
  height: fit-content;
}

.email-marketing-txt-bg {
  background-color: white !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.aviation-upload-modal {
  max-width: 820px !important;
}

.number-bold {
  font-size: 1.125rem;
  font-weight: 500 !important;
}

.name-bold {
  font-size: 0.875rem;
  font-weight: 500 !important;
}

.ysp {
  background: #f2b61c !important;
  color: #fff !important;
}

.bsp {
  background-color: #daf2f3 !important;
}

.email-arr-select {
  width: 158px !important;
}

.badge-box-enabled {
  padding: 6px 5px !important;
}

.jexcel_content,
.jexcel_overflow {
  min-width: 100%;
}

.hide_expired {
  display: none;
}

.hide_border_spreadsheet {
  box-shadow: none !important;
  padding-right: 0 !important;
}

.current-offer-input {
  display: inline-block !important;
  width: 40% !important;
  margin-right: 5px !important;
}

.ck_content_max_width {
  max-width: 360px !important;
  max-height: 300px !important;
  overflow: scroll !important;
}

.purple-border {
  border: 5px solid #9e1dad !important;
}

.gray-border {
  border: 5px solid #dfdfdf !important;
}

.go-top {
  transform: scale(1) !important;
}

.go-top {
  position: fixed;
  border-radius: 50%;
  right: 25px;
  width: 55px;
  height: 55px;
  float: left;
  text-align: center;
  line-height: 55px;
  font-size: 1.125rem;
  background: #f2f2f2;
  box-shadow: -5px 5px 10px rgb(0 0 0 / 10%);
  z-index: 99999;
  opacity: 1;
  visibility: visible;
  bottom: 90px;
  transform: scale(0);
}

.go-top:hover {
  border: 2px solid #47ad1d;
}

.margin-right-click {
  margin-right: 0px !important;
}

.sm-text {
  font-size: 13px !important;
  margin-left: 0px !important;
}

.w-40 {
  width: 40% !important;
}

.overall_motivation_oi.ck-content {
  height: 270px !important;
}

.multiple-listing-agreement-descp {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  font-weight: 400 !important;
}

.animal-img {
  margin-top: 30px !important;
  width: 150px !important;
  height: 150px !important;
}

.animal-img img {
  width: 100%;
  height: 100%;
  display: block;
}

.capitalize-first-letter::first-letter {
  text-transform: capitalize !important;
}

.no-pointer-event {
  pointer-events: none;
  cursor: not-allowed !important;
}

.env_box .env_container {
  width: 120px !important;
  height: auto !important;
  margin: 20px;
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.sustainability-table {
  width: 35% !important;
}

.env_main {
  width: 60% !important;
}

.ei__header_zindex {
  z-index: 999999999 !important;
}

.ei__rtlc-img {
  width: 162px !important;
}

.ei__img-content {
  width: 327px !important;
}

.eiHeader1 {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 0;
  z-index: 999999 !important;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}

.eiHeader1:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgb(0 0 0 / 10%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.eiHeader1:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-100%);
}

.eiHeader1 nav {
  position: relative;
  z-index: 2;
}

.fixed header.eiHeader1:after {
  transform: none;
}

.note-width {
  width: 250px;
  overflow: hidden;
  white-space: normal;
}

.inv-txt {
  font-weight: 1000;
  font-size: 0.999rem;
}

.payment-info-text {
  margin-bottom: 0.8rem !important;
}

.payment-info-text label {
  font-size: 0.9rem !important;
  margin-bottom: 0.125rem !important;
  font-weight: 600 !important;
}

.sustainability-pdf .sustainability-person {
  display: flex !important;
  align-items: center !important;
  max-width: 990px !important;
  flex-wrap: wrap !important;
}

.html2canvas-container {
  width: 100%;
  height: 100%;
}

.work-out-td.ytd {
  background: yellow !important;
}

#messageNotes {
  min-height: 130px !important;
}

.bold-font {
  font-weight: 600 !important;
}

.asking-price-tool {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
}

.to-margin {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.875rem !important;
}

.usd-input-group .input-group-text {
  background-color: #f5f5f5;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 7;
  height: calc(100% - 1px);
  width: 54px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 4px !important;
  font-size: 0.875rem;
}

.input-group.usd-input-group input.form-control {
  padding-left: 70px;
}

.btn-verify {
  background-color: #f2b61c !important;
  border-color: white !important;
  color: white !important;
  cursor: pointer !important;
}

.buyer-arr-thumbnail {
  width: 83px !important;
  margin-right: 10px !important;
}

.ps-map canvas {
  max-width: 230px !important;
}

.context-menu {
  cursor: context-menu;
}

.other-pref-table.table> :not(caption)>*>* {
  border: 1px solid black !important;
}

.other-pref-table.table> :not(caption)>*>* {
  padding: 0.7rem !important;
}

.barr_total-emails {
  margin-top: 1rem;
  align-items: start !important;
  gap: 30px !important;
}

.barr_total-emails p span {
  font-size: 1rem !important;
}

.barr_email-list {
  width: 280px;
  margin-bottom: 40px;
}

.barr_email-list p {
  margin-bottom: 15px !important;
}

.barr_email-list .barr_email-counts {
  font-weight: 600;
  font-size: 0.875rem;
}

.barr_progress {
  height: 0.3rem;
  background-color: #f5f5f5;
  border-radius: 0.2rem;
}

.barr_progress-bar {
  height: 100%;
}

.barr_table-hover>tbody>tr:hover {
  background-color: #eff2f7 !important;
  cursor: pointer;
}

.bvw_label,
.bvw_value {
  display: inline-block;
  font-size: 0.875rem;
}

.bvw_label {
  font-weight: 500;
}

.bvw_value {
  margin-left: 140px;
  font-weight: 400;
}

.bvw_body {
  padding-left: 10px;
}

.bvw_btn button {
  padding-left: 10px;
  font-weight: 600;
  font-size: 1rem;
}

.offcanvas.offcanvas-end.bvw_width {
  width: 950px;
  max-width: 950px !important;
}

.btn.btn-other {
  background-color: rgb(71, 173, 29) !important;
  margin-left: 0px !important;
  color: white !important;
}

.btn.btn-other-email {
  background-color: rgb(71, 173, 29) !important;
  margin-left: 50px !important;
  color: white !important;
}

.bg-purple {
  background-color: #986ef1;
}

.title-status-text {
  color: white !important;
  font-weight: 700 !important;
}

.has-notify .chat-dp,
.chat-dp {
  color: #fff !important;
  font-weight: 500 !important;
}

.has-notify p,
.has-notify div {
  color: #000 !important;
}

.chat-date {
  right: 40px !important;
  top: 25px !important;
}

.received .m-content {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), #a1cf6d !important;
}

.send.message.chat-send {
  padding-left: 30% !important;
}

.message.received .m-content p,
.message.send .m-content p {
  white-space: pre-wrap !important;
}

.message.received .m-content,
.message.send .m-content {
  padding: 10px !important;
}

.chatbox-new {
  background: #fff !important;
  /* padding: 15px 40px 15px 15px !important; */
  padding: 0px !important;
}

.chat-send-btn {
  width: 85px !important;
  border-radius: 0px !important;
  color: #fff !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  font-size: 16px !important;
  gap: 10px !important;
}

.chat-screen.modal-lg {
  width: 700px !important;
  max-width: 700px !important;
}

.chat-box-height {
  height: 135px !important;
  padding: 0px 50px 10px 10px !important;
}

.chat-box-height .input-group-prepend {
  margin-right: 0px !important;
}

.chat-media-tooltip {
  z-index: 999999999999 !important;
}

.has-notify .mb-notify-box {
  margin-top: 5px !important;
  color: #fff !important;
}

.chatbox-footer {
  height: 135px !important;
}

.navbar-toggler {
  width: 60px;
  height: 60px;
  border-radius: 0;
  text-decoration: none;
  padding: 0;
  border: 0;
  background: transparent;
  margin: 0;
}

.navbar-toggler:not([class="collapsed"]),
.navbar-toggler:not([class="collapsed"]):focus,
.navbar-toggler:not([class="collapsed"]):hover {
  color: red !important;
  background: transparent;
  box-shadow: none;
}

.nav-inside {
  width: 100%;
  padding: 0;
}

.header-icon-item {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.button-notification-count {
  position: absolute;
  /* top: -5px;
  right: 15px; */
  border-radius: 50rem;
  box-shadow: 0 0 0 2px #fff;
  width: 15px;
  height: 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  background: var(--red-color);
  color: #fff;
}

.chat-loader {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid #b6d5a9;
  border-right-color: #46ac1c;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.offcanvas-header .chatbox-header {
  padding: 20px 40px 20px !important;
}

.chatbox-footer {
  border-top: 0px solid #efefef !important;
}

.chat-box-height.custom-form-control {
  background: #fff !important;
}

.message.send.chat-send {
  padding-right: 10px !important;
}

.message.send {
  padding-right: 10px !important;
}

.chat-new-body .message.send,
.chat-new-body .message.received {
  width: fit-content !important;
  max-width: 66% !important;
}

.active-selected-chat {
  cursor: auto !important;
  pointer-events: none !important;
}

.custom-react-tooltip {
  max-width: 650px;
}

.chat-outer {
  height: 100vh !important;
}

.chat-sidenav.side-nav {
  height: 100vh !important;
}

.offcanvas-header.chatbox-header {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.cmb-img.chat-cmb {
  height: 40px !important;
  width: 40px !important;
}

.chat-lg-footer {
  padding: 0px !important;
  height: 135px !important;
}

.chat-lg-footer .custom-input-group {
  height: 135px !important;
}

.chat-lg-footer .input-group-append {
  width: 90px !important;
  height: 135px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  gap: 10px !important;
  border-radius: 0px !important;
  color: #fff !important;
}

.chat-lg-footer .input-group-append a {
  display: flex;
  flex-direction: column !important;
  gap: 10px;
}

.chat-lg-footer textarea {
  width: calc(100% - 90px) !important;
  margin: 0px !important;
  background: #fff !important;
  line-height: 1.5;
}

.chat-lg-footer .input-group-prepend {
  margin-left: 10px !important;
  margin-right: 0px !important;
}

.chat-page-textarea.custom-send-input {
  line-height: 1.5 !important;
}

.chat-send-btn-disabled {
  cursor: auto !important;
  opacity: 0.7 !important;
}

.chat-send-btn-disabled a,
.chat-send-btn-disabled {
  cursor: auto !important;
}

.chat-lg-footer .input-group-append a:hover {
  color: #fff !important;
}

span.notify-count {
  width: 28px !important;
  height: 28px !important;
  top: -15px !important;
  right: -5px !important;
}

.custom-form-control textarea {
  line-height: 1.5 !important;
}

.chat-page-texteditor .ck-editor .ck-editor__main .ck-editor__editable_inline {
  height: 90px !important;
  padding: 0px 10px !important;
  line-height: 1.5 !important;
  border: 0px solid transparent !important;
}

.chat-page-texteditor .ck-editor .ck-editor__main .ck-editor__editable_inline p {
  margin-bottom: 0px !important;
}

.chat-page-texteditor.custom-send-input {
  padding: 0px 0px !important;
  margin: 0px !important;
}

.chat-page-texteditor .ck-editor .ck.ck-editor__top.ck-reset_all .ck-sticky-panel .ck-toolbar {
  border: 0px solid transparent !important;
}

.ck-content p {
  margin-bottom: 0px !important;
}

.more-icon-size {
  height: 20px;
  width: 20px;
}

/* .message.send,
.message.received {
  align-items: flex-start !important;
} */

.more-icon-display {
  display: none !important;
}

.more-icon-display:hover {
  display: inline-block !important;
}

.reminder-msg-container p {
  margin-bottom: 10px !important;
}

.reminder-msg-container {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  border-bottom: 1px solid #efefef !important;
}

.reminder-msg-container:last-child {
  border-bottom: 0px solid #efefef !important;
}

.reminder-count {
  font-weight: 600 !important;
}

.reminder-date .react-datepicker-wrapper {
  width: 220px !important;
}

.reminder-date:first-child {
  border-bottom: 1px solid #efefef !important;
}

.reminder-date {
  padding-bottom: 15px;
  width: 100% !important;
}

.reminder-date-z-index {
  z-index: 999999999 !important;
}

.ck-editor__editable_inline {
  padding: 0px 10px !important;
}

.nps-container {
  vertical-align: middle !important;
  text-align: center !important;
}

.nps-container a {
  padding: 15px 30px !important;
  font-size: 1rem !important;
}

.sticky-header-table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.table-header {
  background-color: #f8f8f8;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
}

.sticky-pagination {
  background-color: #fff;
  position: sticky;
  bottom: 0;
  z-index: 1;
  width: 100%;
}

.table-body {
  display: block;
  max-height: 85vh;
  min-height: 25vh;
  overflow: auto;
  width: 100%;
}

.sort-by-custom {
  position: relative;
  display: inline-block;
  padding-right: 14px;
}

.custom-thead,
.table-body tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.suggestions {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #d4d4d4;
  background-color: #f7f7f7;
  border-radius: 0 0 10px 10px;
}

.suggestions li {
  list-style: none;
  padding: 10px;
  cursor: pointer;
}

.autocomplete li:hover {
  background-color: #d4d4d4;
}

.text-wrapper {
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.more-description-box {
  max-width: 200px;
  overflow: hidden;
}

.more-description-box>* {
  max-width: 100%;
  box-sizing: border-box;
}

.ck-editor__editable_inline ul,
.ck-content ul {
  margin-left: 20px !important;
}

.ck-editor__editable_inline ul strong {
  padding: 0 !important;
}

.ipr_label {
  width: 50px !important;
}

.ipr_conatiner {
  margin-bottom: 15px !important;
}

.ck-content.ipr_table figure {
  display: block !important;
}

.ck-content.ipr_table figure,
.ck-content.ipr_table figure table {
  width: 100%;
}

.select-show-by {
  position: absolute !important;
  width: 200px;
}

.btn-primary-group:hover {
  background: #227300 !important;
}

.parts-section-th {
  white-space: break-spaces !important;
  width: 250px !important;
  max-width: 250px !important;
}

.parts-section-descp-th {
  white-space: break-spaces !important;
  width: 350px !important;
  max-width: 350px !important;
}

.ck-content.ipr_table figure {
  display: inline-block !important;
  width: 100% !important;
}

.ck-content.ipr_table figure table {
  width: 90% !important;
}

.ck-content.ipr_table span table,
.ck-content.ipr_table span table tr td,
.ck-content.ipr_table span table th,
.ck-content.ipr_table span table td {
  border: 1px solid black !important;
  border-collapse: collapse !important;
  padding: 7px !important;
}

.ck-content.ipr_table span table {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.ck-content.ipr_table span>p {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

.jexcel_content.hide_border_spreadsheet {
  overflow: hidden !important;
}

.igr-new-release .igr-box {
  width: 100% !important;
  display: flex !important;
}

.igr-new-release .igr-box .nr-pd {
  width: 100% !important;
}

.igr-new-release .igr-box .nr-pd1 {
  width: 100% !important;
  padding-left: 8px !important;
}

.nr-name {
  color: #47ad1d !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.create-invoice textarea.form-control.nr-descp {
  height: 300px;
  resize: none;
}

.ck-content.nr-descp .ck-rounded-corners .ck-editor__main .ck-editor__editable_inline {
  height: 300px !important;
}

.nr-email {
  width: 50% !important;
  padding-right: 5px !important;
}

.separator-right {
  border-left: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.upload-nda-btn .btn {
  padding: 15px !important;
  font-size: 15px !important;
}

.nda-upload-file {
  align-items: center !important;
  width: 100% !important;
  display: flex !important;
  gap: 25px !important;
}

.nda-upload-file p {
  color: #47ad1d !important;
  text-decoration: underline !important;
}

.custom-offcanvas-mobile {
  z-index: 99999;
  width: 100vw !important;
  border-left: 0 !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.offcanvas-body-custom {
  flex-grow: 1;
  padding: 0 0;
  overflow-y: auto;
}

.form-check input[type="checkbox"]+.small-check-box::before {
  left: 2px !important;
  top: 2px !important;
  width: 15px !important;
  height: 15px !important;
}

.form-check input[type="checkbox"]+.small-check-box::after {
  left: 2px !important;
  top: 2px !important;
  width: 15px !important;
  height: 15px !important;
}

.custom-header-search-box {
  max-width: 100% !important;
  margin-left: 0 !important;
}

.left-arrow-accordion .accordion-button:before {
  left: 5px !important;
}

.modal-xxxl {
  max-width: 1300px !important;
}

.padding-td{
  padding: 10px 16px !important;
}

@media (min-width: 991.98px) {
  .mobile-view-nav {
    display: none !important;
  }

  .desktop-titlebar {
    min-height: 157.5px;
    max-height: 157.5px;
  }

  .custom-offcanvas-mobile {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .mobile-view-container {
    width: calc(100% - 0px);
  }

  .header-search-box {
    margin-left: 10px;
  }

  .col-row-item {
    width: 100%;
  }

  .dpc-col-column {
    width: 100%;
  }

  .dpc-col-column-custom1 {
    width: 100% !important;
    padding: 6px !important;
  }

  .dpc-col-column-custom2 {
    width: 100% !important;
    padding: 6px !important;
  }

  .dpc-col-column-half {
    width: 100% !important;
    padding: 6px !important;
  }

  .right-toggle-button .dropdown-menu {
    min-width: 100vw !important;
  }

  .vw-100 {
    width: 100vw !important;
  }

  .flex-member-row {
    position: relative;
    height: auto !important;
    padding-top: 80px;
    min-height: 100vh;
  }

  .flex-member-column {
    padding: 20px 20px 20px;
    width: 100% !important;
    align-self: center;
  }

  .member-logo-area {
    width: 100px;
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .flex-member-form-box h1 {
    margin-bottom: 40px;
  }

  .form-button .btn {
    min-width: 100% !important;
  }

  .tb-filter-row .filter-tags,
  .tb-filter-right {
    width: 100%;
  }

  .filter-title {
    font-size: 14px;
  }

  .res-primary tr {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .desktop-nav {
    display: none !important;
  }

  .main-menu-compressed {
    width: 100% !important;
    margin-left: auto !important;
    padding: 0;
  }

  .sticky-top {
    top: 80px !important;
  }

  .listing-page-padding-top {
    padding-top: 79px !important;
  }

  .open-menu .side-nav-inside .accordion-button {
    width: 100% !important;
  }

  .main-menu-expanded {
    width: 100% !important;
    margin-left: auto !important;
  }

  .accordion-button svg {
    position: absolute;
    left: 15px;
    transform: none;
    top: 8px;
  }

  .side-nav-inside .accordion-button {
    font-size: 0.875rem;
    padding: 0px 12px 0px 50px;
    font-weight: 500;
    width: 86px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: 0.2s all ease;
    color: var(--black-color);
  }

  .side-nav-inside .accordion-body ul li>a {
    font-size: 0.875rem;
    color: #000;
    padding: 4.5px 48px 4.5px 50px;
    width: 100%;
    font-weight: 500;
    position: relative;
  }

  .open-menu .side-nav {
    width: 100vw !important;
    height: 100vh !important;
  }

  .close-nav {
    width: 30px;
    filter: brightness(0) invert(1);
    opacity: 1;
  }

  .side-nav .profile-img {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0;
    position: absolute;
    left: 5px;
    top: 5px;
  }

  .profile-area {
    position: relative;
    padding: 15px 0px 15px 50px;
    height: 60px;
    white-space: nowrap;
    background: #227300;
    cursor: pointer;
  }

  .custom-profile-mobile {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: 50%;
    background: #227300;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 1px;
    padding: 8px;
    color: rgba(0, 0, 0, 0.83);
    font-family: "Poppins", sans-serif;
  }

  .d-md-flex {
    display: flex !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .listing-container-listing {
    width: 100% !important;
  }
}

table.seller-detail-option tr td {
  border: 0px !important;
}

.spi_padding {
  padding: 7px !important;
}

.spi_padding a:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}